import { useQuery } from '@tanstack/react-query'
import React, { useState } from 'react'

import api from '../../../../api'
import { ResultWithPagination } from '../../../../components/DataTable/DataTable.types'
import MultipleAutocomplete from '../../../../components/MultipleAutocomplete'
import { ICreator } from '../../../../types/learn/creator.interface'

interface Props {
  initial?: { label: string; value: string }[]
  creators: string[]
  setCreators: (categories: string[]) => void
}

const CreatorsSelect: React.FC<Props> = ({
  initial,
  creators,
  setCreators
}) => {
  const [search, setSearch] = useState<string>('')

  const { data, isLoading } = useQuery({
    queryKey: ['creators', search],
    queryFn: async () => {
      const response = await api<ResultWithPagination<ICreator>>(
        'admin/learn/creators',
        {
          method: 'GET',
          params: {
            search
          }
        }
      )
      if (response.data.result && response.data.result.length) {
        const result = response.data.result.map(item => ({
          label: item.name,
          value: item.id
        }))
        if (initial) {
          initial.forEach(item => {
            if (!result.find(i => i.value === item.value)) {
              result.unshift(item)
            }
          })
        }
        return result
      }
    }
  })

  return (
    <MultipleAutocomplete
      search={search}
      setSearch={setSearch}
      values={creators}
      setValues={setCreators}
      data={data || []}
      title='Select Creators'
      loading={isLoading}
    />
  )
}

export default CreatorsSelect
