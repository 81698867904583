import { ColumnDef } from '@tanstack/react-table'
import toast from 'react-hot-toast'

import { DataTableColumnHeader } from '../../components/DataTable/ColumnHeader'
import FailureCodeComponent from '../../components/FailureCode'
import ProtectedComponent from '../../components/ProtectedComponent'
import {
  BankVerificationResult,
  IBankVerification
} from '../../types/bank-verification/bank-verification.interface'
import formatId from '../../utils/formatId'

import UnblockUser from './Unblock'

export const columns: ColumnDef<IBankVerification>[] = [
  {
    accessorKey: 'id',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Id' />
    },
    cell: ({ row }) => {
      return (
        <button
          onClick={() => {
            navigator.clipboard.writeText(`${row.original.id}`)
            toast.success('ID Copied to clipboard')
          }}
        >
          {formatId(row.original.id)}
        </button>
      )
    }
  },
  {
    accessorKey: 'provider_id',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Provider ID' />
    },
    cell: ({ row }) => {
      return (
        <button
          onClick={() => {
            navigator.clipboard.writeText(`${row.original.provider_id}`)
            toast.success('Provider ID Copied to clipboard')
          }}
        >
          {formatId(row.original.provider_id)}
        </button>
      )
    }
  },
  {
    accessorKey: 'legal_name',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Legal Name' />
    },
    cell: ({ row }) => {
      if (
        row.original.account.kyc_status === 'verified' ||
        row.original.account.kyc_status === 'verified_no_ssn'
      ) {
        return (
          <p>
            {row.original.account.first_name} {row.original.account.last_name}
          </p>
        )
      } else {
        return <p className='min-w-28 opacity-50'>Not Verified</p>
      }
    },
    enableSorting: false
  },
  {
    accessorKey: 'account.username',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='@user' />
    },
    cell: ({ row }) => {
      return (
        <button
          onClick={() => {
            navigator.clipboard.writeText(`@${row.original.account.username}`)
            toast.success('Username Copied to clipboard')
          }}
          className='opacity-50'
        >
          @{row.original.account.username}
        </button>
      )
    },
    enableSorting: false
  },
  {
    accessorKey: 'method',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Method' />
    },
    cell: ({ row }) => {
      const method = row.original.method

      return (
        <button
          className='opacity-50'
          onClick={() => {
            if (!method) return
            navigator.clipboard.writeText(method)
            toast.success('Method Copied to clipboard')
          }}
        >
          {method
            .replace(/_/g, ' ')
            .toLowerCase()
            .replace(/\b\w/g, l => l.toUpperCase())}
        </button>
      )
    }
  },
  {
    accessorKey: 'status',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Status' />
    },
    cell: ({ row }) => {
      const status = row.original.status

      return (
        <button
          className='opacity-50'
          onClick={() => {
            if (!status) return
            navigator.clipboard.writeText(status)
            toast.success('Status Copied to clipboard')
          }}
        >
          {status
            .replace(/_/g, ' ')
            .toLowerCase()
            .replace(/\b\w/g, l => l.toUpperCase())}
        </button>
      )
    }
  },
  {
    accessorKey: 'result',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Result' />
    },
    cell: ({ row }) => {
      const result = row.original.result

      return (
        <button
          className='opacity-50'
          onClick={() => {
            if (!result) return
            navigator.clipboard.writeText(result)
            toast.success('Result Copied to clipboard')
          }}
        >
          {result
            ? result
                .replace(/_/g, ' ')
                .toLowerCase()
                .replace(/\b\w/g, l => l.toUpperCase())
            : 'N/A'}
        </button>
      )
    }
  },
  {
    accessorKey: 'failure_codes',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Failure Codes' />
    },
    cell: ({ row }) => {
      const codes = row.original.failure_codes

      return (
        <div className='opacity-50'>
          {codes.length > 0 ? (
            <>
              {codes.map((code, index) => (
                <FailureCodeComponent key={index} failureCode={code} />
              ))}
            </>
          ) : (
            'N/A'
          )}
        </div>
      )
    },
    enableSorting: false
  },
  {
    accessorKey: 'created_at',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Created At' />
    },
    cell: ({ row }) => {
      return (
        <p className='opacity-50'>
          {new Date(row.original.created_at).toLocaleDateString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            day: 'numeric',
            month: 'short'
          })}
        </p>
      )
    }
  },
  {
    accessorKey: 'actions',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='' />
    },
    cell: ({ row }) => {
      if (
        row.original.result === BankVerificationResult.FAILED &&
        row.original.account.is_kyc_blocked
      ) {
        return (
          <ProtectedComponent permissionSlug='kyc_verifications_unblock'>
            <div className='flex w-fit flex-row gap-1 border-l-2 border-border pl-4'>
              {<UnblockUser id={row.original.id} />}
            </div>
          </ProtectedComponent>
        )
      }
    },
    enableSorting: false
  }
]
