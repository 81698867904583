import clsx from 'clsx'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { ReactComponent as GearIcon } from '../../assets/icons/icon_gear.svg'
import { ReactComponent as LogoIcon } from '../../assets/icons/icon_logo.svg'
import { ReactComponent as SignOutIcon } from '../../assets/icons/icon_right_from_bracket.svg'
import { useActions } from '../../hooks/use-actions'
import { useAuth } from '../../hooks/use-auth'
import { Icon } from '../Icon'
import Popover from '../Popover'

import AvgFeeAndBtcPrice from './AvgFeeAndBtcPrice'

export const Header = () => {
  const { admin } = useAuth()
  const { logout } = useActions()

  const navigate = useNavigate()
  const [isMenuVisible, setIsMenuVisible] = useState<boolean>(false)

  const handleSignOut = () => {
    logout()

    navigate('/login', { replace: true })
  }

  const toggleMenu = () => {
    setIsMenuVisible(!isMenuVisible)
  }

  return (
    <header className='bg-grey900 flex h-full items-center justify-between border-b border-white/15 p-4 pl-7'>
      <div className='flex items-center gap-[10px]'>
        <LogoIcon />
        <h1 className='text-lg font-bold text-white'>Admin</h1>
      </div>

      {!admin && (
        <Link
          to='/login'
          className='cursor-pointer text-sm text-white/50 hover:text-white'
        >
          Sign in
        </Link>
      )}
      {admin && (
        <>
          <AvgFeeAndBtcPrice />
          <div
            className='flex cursor-pointer select-none items-center justify-between gap-2'
            id='accountMenu'
            onClick={toggleMenu}
          >
            <div className='mr-1 h-8 w-8 overflow-hidden rounded-full bg-zinc800'>
              {admin.avatar && <img src={admin.avatar} alt='Avatar' />}
            </div>
            <span className='text-sm text-white/50'>{admin.email}</span>
            <Icon
              className={clsx('text-white/50', {
                'rotate-0': !isMenuVisible,
                'rotate-180': isMenuVisible
              })}
              icon='caretDown'
            />
          </div>
          <Popover
            target='#accountMenu'
            offsetY={8}
            visible={isMenuVisible}
            onClose={() => setIsMenuVisible(false)}
          >
            <div className='flex flex-col'>
              {[
                {
                  icon: <GearIcon className='h-[14px] w-[14px] fill-white' />,
                  label: 'Settings',
                  onClick: () => {
                    navigate('/settings')
                    setIsMenuVisible(false)
                  }
                },
                {
                  icon: (
                    <SignOutIcon className='h-[14px] w-[14px] fill-white' />
                  ),
                  label: 'Sign out',
                  onClick: () => {
                    handleSignOut()
                    setIsMenuVisible(false)
                  }
                }
              ].map((item, i: number) => (
                <button
                  key={i}
                  className='flex h-8 items-center justify-start gap-2 px-3 hover:bg-white/10'
                  onClick={item.onClick}
                >
                  {item.icon}
                  <span className='text-sm'>{item.label}</span>
                </button>
              ))}
            </div>
          </Popover>
        </>
      )}
    </header>
  )
}
