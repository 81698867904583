import clsx from 'clsx'
import React from 'react'

export function View({
  children,
  className
}: {
  children: React.ReactNode
  className?: string
}) {
  return (
    <div className={clsx('h-full w-full bg-gray900', className)}>
      {children}
    </div>
  )
}
