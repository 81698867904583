import { ColumnDef } from '@tanstack/react-table'
import toast from 'react-hot-toast'

import { DataTableColumnHeader } from '../../components/DataTable/ColumnHeader'
import { IBankEddRequest } from '../../types/bank-edd-request/bank-request.interface'
import formatId from '../../utils/formatId'

import ViewEDDRequest from './ViewEDDRequest'

export const columns: ColumnDef<IBankEddRequest>[] = [
  {
    accessorKey: 'id',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Id' />
    },
    cell: ({ row }) => {
      return (
        <button
          onClick={() => {
            navigator.clipboard.writeText(`${row.original.id}`)
            toast.success('ID Copied to clipboard')
          }}
        >
          {formatId(row.original.id)}
        </button>
      )
    }
  },
  {
    accessorKey: 'account.username',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='@user' />
    },
    cell: ({ row }) => {
      return (
        <button
          onClick={() => {
            navigator.clipboard.writeText(`@${row.original.account.username}`)
            toast.success('Username Copied to clipboard')
          }}
          className='opacity-50'
        >
          @{row.original.account.username}
        </button>
      )
    },
    enableSorting: false
  },
  {
    accessorKey: 'first_name',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='First Name' />
    },
    cell: ({ row }) => {
      return (
        <p className='opacity-50'>
          {row.original.first_name_visible
            ? row.original.first_name || 'n/a'
            : 'N/A'}
        </p>
      )
    }
  },
  {
    accessorKey: 'last_name',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Last Name' />
    },
    cell: ({ row }) => {
      return (
        <p className='opacity-50'>
          {row.original.last_name_visible
            ? row.original.last_name || 'n/a'
            : 'N/A'}
        </p>
      )
    }
  },
  {
    accessorKey: 'address_one',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Address one' />
    },
    cell: ({ row }) => {
      return (
        <p className='opacity-50'>
          {row.original.address_one_visible
            ? row.original.address_one || 'n/a'
            : 'N/A'}
        </p>
      )
    }
  },
  {
    accessorKey: 'address_two',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Address two' />
    },
    cell: ({ row }) => {
      return (
        <p className='opacity-50'>
          {row.original.address_two_visible
            ? row.original.address_two || 'n/a'
            : 'N/A'}
        </p>
      )
    }
  },
  {
    accessorKey: 'city',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='City' />
    },
    cell: ({ row }) => {
      return (
        <p className='opacity-50'>
          {row.original.city_visible ? row.original.city || 'n/a' : 'N/A'}
        </p>
      )
    }
  },
  {
    accessorKey: 'zip',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Zip' />
    },
    cell: ({ row }) => {
      return (
        <p className='opacity-50'>
          {row.original.zip_visible ? row.original.zip || 'n/a' : 'N/A'}
        </p>
      )
    }
  },
  {
    accessorKey: 'id_number_type',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='id_number_type' />
    },
    cell: ({ row }) => {
      if (!row.original.id_number_type_visible) {
        return <p className='opacity-50'>N/A</p>
      }

      const value = row.original.id_number_type

      return (
        <p className='opacity-50'>
          {value
            ? value
                .replace(/_/g, ' ')
                .toLowerCase()
                .replace(/\b\w/g, l => l.toUpperCase())
            : 'n/a'}
        </p>
      )
    }
  },
  {
    accessorKey: 'phone_number',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Phone Number' />
    },
    cell: ({ row }) => {
      return (
        <p className='opacity-50'>
          {row.original.phone_number_visible
            ? row.original.phone_number || 'n/a'
            : 'N/A'}
        </p>
      )
    }
  },
  {
    accessorKey: 'employment_status',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Employment Status' />
    },
    cell: ({ row }) => {
      if (!row.original.employment_status_visible) {
        return <p className='opacity-50'>N/A</p>
      }

      const value = row.original.employment_status

      return (
        <p className='opacity-50'>
          {value
            ? value
                .replace(/_/g, ' ')
                .toLowerCase()
                .replace(/\b\w/g, l => l.toUpperCase())
            : 'n/a'}
        </p>
      )
    }
  },
  {
    accessorKey: 'industry',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Industry' />
    },
    cell: ({ row }) => {
      if (!row.original.industry_visible) {
        return <p className='opacity-50'>N/A</p>
      }

      const value = row.original.industry

      return (
        <p className='opacity-50'>
          {value
            ? value
                .replace(/_/g, ' ')
                .toLowerCase()
                .replace(/\b\w/g, l => l.toUpperCase())
            : 'n/a'}
        </p>
      )
    }
  },
  {
    accessorKey: 'source_of_funds',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Source of Funds' />
    },
    cell: ({ row }) => {
      if (!row.original.source_of_funds_visible) {
        return <p className='opacity-50'>N/A</p>
      }

      const value = row.original.source_of_funds

      return (
        <p className='opacity-50'>
          {value
            ? value
                .replace(/_/g, ' ')
                .toLowerCase()
                .replace(/\b\w/g, l => l.toUpperCase())
            : 'n/a'}
        </p>
      )
    }
  },
  {
    accessorKey: 'onboarded_location',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Onboarded Location' />
    },
    cell: ({ row }) => {
      return (
        <p className='opacity-50'>
          {row.original.onboarded_location_visible
            ? row.original.onboarded_location || 'n/a'
            : 'N/A'}
        </p>
      )
    }
  },
  {
    accessorKey: 'jurisdiction_code',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Jurisdiction Code' />
    },
    cell: ({ row }) => {
      return (
        <p className='opacity-50'>
          {row.original.jurisdiction_code_visible
            ? row.original.jurisdiction_code || 'n/a'
            : 'N/A'}
        </p>
      )
    }
  },
  {
    accessorKey: 'citizenship_code',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Citizenship Code' />
    },
    cell: ({ row }) => {
      return (
        <p className='opacity-50'>
          {row.original.citizenship_code_visible
            ? row.original.citizenship_code || 'n/a'
            : 'N/A'}
        </p>
      )
    }
  },
  {
    accessorKey: 'created_at',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Created At' />
    },
    cell: ({ row }) => {
      return (
        <p className='opacity-50'>
          {new Date(row.original.created_at).toLocaleDateString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            day: 'numeric',
            month: 'short'
          })}
        </p>
      )
    }
  },
  {
    accessorKey: 'actions',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='' />
    },
    cell: ({ row }) => {
      return (
        <div className='flex w-fit flex-row gap-1 border-l-2 border-border pl-4'>
          <ViewEDDRequest initial={row.original} />
        </div>
      )
    },
    enableSorting: false
  }
]
