import { Table } from '@tanstack/react-table'
import {
  ArrowBigLeftDash,
  ArrowBigRightDash,
  ArrowLeft,
  ArrowRight
} from 'lucide-react'

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from './Select'

interface DataTablePaginationProps<TData> {
  table: Table<TData>
}

export function DataTablePagination<TData>({
  table
}: DataTablePaginationProps<TData>) {
  return (
    <div className='flex flex-wrap items-center justify-between gap-2 sm:justify-normal md:gap-4'>
      <div className='flex w-full items-center justify-between gap-2 sm:w-fit '>
        <div className='w-40 text-sm font-medium'>Per page</div>
        <Select
          value={String(table.getState().pagination.pageSize)}
          onValueChange={value => {
            table.setPageSize(Number(value))
          }}
        >
          <SelectTrigger className='h-8 w-[70px] !rounded-[32px] !px-4 !py-5'>
            <SelectValue placeholder={table.getState().pagination.pageSize} />
          </SelectTrigger>
          <SelectContent side='top'>
            {[5, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 150, 200].map(
              pageSize => (
                <SelectItem key={pageSize} value={`${pageSize}`}>
                  {pageSize}
                </SelectItem>
              )
            )}
          </SelectContent>
        </Select>
      </div>
      <div className='flex items-center justify-center text-sm font-medium'>
        Page {table.getState().pagination.pageIndex + 1} of{' '}
        {table.getPageCount()}
      </div>
      <div className='ml-auto flex items-center gap-1 md:gap-2'>
        <button
          className='h-8 w-8 p-0 disabled:cursor-not-allowed disabled:text-gray-400'
          onClick={() => table.setPageIndex(0)}
          disabled={!table.getCanPreviousPage()}
        >
          <span className='sr-only'>Go to first page</span>
          <ArrowBigLeftDash className='h-4 w-4' />
        </button>
        <button
          className='h-8 w-8 p-0 disabled:cursor-not-allowed disabled:text-gray-400'
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          <span className='sr-only'>Go to previous page</span>
          <ArrowLeft className='h-4 w-4' />
        </button>
        <button
          className='h-8 w-8 p-0 disabled:cursor-not-allowed disabled:text-gray-400'
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          <span className='sr-only'>Go to next page</span>
          <ArrowRight className='h-4 w-4' />
        </button>
        <button
          className='h-8 w-8 p-0 disabled:cursor-not-allowed disabled:text-gray-400'
          onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          disabled={!table.getCanNextPage()}
        >
          <ArrowBigRightDash className='h-4 w-4' />
          <span className='sr-only'>Go to last page</span>
        </button>
      </div>
    </div>
  )
}
