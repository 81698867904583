import clsx from 'clsx'
import React, { ReactNode, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

import { ReactComponent as CloseIcon } from '../../assets/icons/icon_close.svg'

import styles from './modal.module.css'

type TModalContext = {
  onClose: () => void
}

interface ModalProps {
  children?: ReactNode | ReactNode[]
  className?: string
  visible?: boolean
  onClose?: () => void
  overflow?: boolean
}

interface ModalHeaderProps {
  className?: string
  title: string
  closable?: boolean
  modalContext?: TModalContext
}

interface ModalContentProps {
  children?: ReactNode | ReactNode[]
  className?: string
}

interface ModalFooterProps {
  children?: ReactNode | ReactNode[]
  className?: string
}

const ANIMATION_DURATION = 200
function Modal({
  children,
  className,
  overflow = true,
  visible: propsVisible,
  onClose: propsOnClose
}: ModalProps) {
  const [visible, setVisible] = useState<boolean>(!!propsVisible)
  const [hide, setHide] = useState(!visible)

  useEffect(() => {
    document.body.style.overflow = !hide ? 'hidden' : 'unset'
  }, [hide])

  useEffect(() => {
    setVisible(!!propsVisible)
    setHide(!propsVisible)
  }, [propsVisible])

  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      if (e.code === 'Escape') {
        onClose()
      }
    }
    document.addEventListener('keyup', handler)
    return () => {
      document.removeEventListener('keyup', handler)
    }
  }, [])

  const onClose = () => {
    setHide(true)
    setTimeout(() => {
      setVisible(false)
      propsOnClose && propsOnClose()
      setHide(false)
    }, ANIMATION_DURATION)
  }

  return visible
    ? createPortal(
        <div
          className={clsx(
            'fixed inset-0 flex h-screen w-screen items-center justify-center backdrop-blur',
            {
              [styles.modalContainerVisible]: !hide,
              [styles.modalContainerHidden]: hide
            }
          )}
          onClick={onClose}
          style={{
            animationDuration: `${ANIMATION_DURATION}ms`,
            zIndex: 50
          }}
        >
          <div
            onClick={e => {
              e.stopPropagation()
            }}
            className={clsx(
              'max-h-full min-w-full rounded-[30px] border-2 border-gray500/20 bg-gray900 p-[30px] sm:min-w-[500px] sm:max-w-[500px]',
              className,
              {
                'overflow-auto': overflow,
                'overflow-visible': !overflow
              }
            )}
          >
            {React.Children.map(children, (Child: any, index) => {
              return (
                <React.Fragment key={index}>
                  {React.cloneElement(Child, {
                    modalContext: { onClose }
                  })}
                </React.Fragment>
              )
            })}
          </div>
        </div>,
        document.body
      )
    : null
}

const ModalHeader = function ({
  className,
  title,
  closable,
  modalContext
}: ModalHeaderProps) {
  return (
    <div className={clsx(className, 'flex items-center justify-between')}>
      <span className='text-[20px] font-bold text-white'>{title}</span>
      {closable && (
        <CloseIcon
          onClick={modalContext?.onClose}
          className='cursor-pointer fill-white opacity-50 transition-opacity hover:opacity-80 active:opacity-100'
        />
      )}
    </div>
  )
}

Modal.Header = ModalHeader

const ModalContent = function ({ children, className }: ModalContentProps) {
  return <div className={className}>{children}</div>
}

Modal.Content = ModalContent

const ModalFooter = function ({ children, className }: ModalFooterProps) {
  return (
    <div
      className={clsx(
        'flex h-10 items-center justify-between border-t border-zinc-800 px-1',
        className
      )}
    >
      {children}
    </div>
  )
}

Modal.Footer = ModalFooter

export { Modal }
