import { DataTable } from '../../../components/DataTable'

import { columns } from './columns'

export const Roles = () => {
  return (
    <DataTable
      url='admin/roles'
      totalText='roles'
      columns={columns}
      tableClassName='md:!max-h-[calc(100vh-550px)]'
    />
  )
}
