export function Bitcoin({ className }: { className: string }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='42'
      height='42'
      viewBox='0 0 42 42'
      className={className}
    >
      <g
        id='Group_3092'
        data-name='Group 3092'
        transform='translate(1257.333 15108)'
      >
        <rect
          id='BTCB'
          width='42'
          height='42'
          rx='21'
          transform='translate(-1257.333 -15108)'
          fill='#f7931a'
        />
        <g
          id='Group_2182'
          data-name='Group 2182'
          transform='translate(-1247.156 -15100.699)'
        >
          <path
            id='Path_815'
            data-name='Path 815'
            d='M-1887.929-15277.234l2.318.594-.886,3.714,1.8.425.923-3.7,2.315.588-.943,3.8a10.011,10.011,0,0,1,2.825,1.339,4.485,4.485,0,0,1,1.661,2.072,6.142,6.142,0,0,1,.039,2.68,3.351,3.351,0,0,1-1.289,1.851,3.969,3.969,0,0,1-1.559.721,6.023,6.023,0,0,1,2.009,1.724,4.45,4.45,0,0,1,.448,2.98,5.378,5.378,0,0,1-1.456,2.85,3.674,3.674,0,0,1-1.529,1.065,8.258,8.258,0,0,1-1.607.428,12.533,12.533,0,0,1-3.9-.251l-.948,3.72-2.315-.568.967-3.74-1.844-.473-.938,3.767-2.354-.591,1.031-3.776-4.709-1.174,1.181-2.7s2.322.7,2.535.13,2.732-10.541,2.732-10.541a1.327,1.327,0,0,0-.16-.907c-.2-.282-.607-.461-1.563-.7-.248-.061-.607-.16-1.047-.246l.712-2.455,4.571,1.158Z'
            transform='translate(1897.872 15277.234)'
            fill='#fff'
          />
          <path
            id='Path_816'
            data-name='Path 816'
            d='M-1851.088-15243.712l-1.131,4.6s5.88,1.689,6.03-1.26S-1851.088-15243.712-1851.088-15243.712Z'
            transform='translate(1861.807 15250.752)'
            fill='#f7931a'
          />
          <path
            id='Path_817'
            data-name='Path 817'
            d='M-1861-15205.713l1.315-5.03s6.043.92,5.722,3.788a2.02,2.02,0,0,1-1.581,1.794C-1857.564-15204.656-1861-15205.713-1861-15205.713Z'
            transform='translate(1868.741 15224.706)'
            fill='#f7931a'
          />
        </g>
      </g>
    </svg>
  )
}
