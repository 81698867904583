import { Helmet } from 'react-helmet'

import { AdminTable } from '../components/AdminTable'
import { View } from '../components/View'
import { ServiceData } from '../types/old-table/old-table.types'

const services: ServiceData[] = [
  {
    id: '1',
    name: 'Service 1',
    instanceId: 'I-123456',
    state: 'running',
    type: 't2.micro',
    status: 'healthy',
    alarms: 'No Alarms',
    zone: 'us-west-2a',
    publicIpv4Dns: 'ec2-12-34-56-78.us-west-2.compute.amazonaws.com'
  },
  {
    id: '2',
    name: 'Service 2',
    instanceId: 'I-123456',
    state: 'running',
    type: 't2.micro',
    status: 'healthy',
    alarms: 'No Alarms',
    zone: 'us-west-2a',
    publicIpv4Dns: 'ec2-12-34-56-78.us-west-2.compute.amazonaws.com'
  }
]

export function Health() {
  const onEdit = (id: string) => {
    // eslint-disable-next-line no-console
    console.log('editing user with id:', id)
  }

  return (
    <>
      <Helmet>
        <title>STBA Admin - Health</title>
      </Helmet>
      <View className='px-24 py-12'>
        <h1 className='mb-7 border-b border-white border-opacity-10 pb-7 text-4xl font-bold text-white'>
          Health
        </h1>

        <AdminTable
          type='services'
          header={`${services.length} services`}
          rawData={services}
          onEdit={onEdit}
        />
      </View>
    </>
  )
}
