import clsx from 'clsx'
import { ReactNode } from 'react'

interface SectionProps {
  title: string
  subtitle?: string
  children?: ReactNode | ReactNode[]
  className?: string
}

export const Section = ({
  title,
  subtitle,
  children,
  className
}: SectionProps) => {
  return (
    <div className={clsx('flex w-full flex-col gap-4 md:gap-8', className)}>
      <div className='flex flex-row items-center gap-2 md:gap-4'>
        <p className='text-xl font-bold text-white md:text-3xl'>{title}</p>
        {subtitle && (
          <p className='text-lg font-semibold text-white opacity-50 md:text-2xl'>
            {subtitle}
          </p>
        )}
      </div>
      <div className='flex flex-row flex-wrap gap-[30px]'>{children}</div>
    </div>
  )
}
