import { ColumnDef } from '@tanstack/react-table'
import toast from 'react-hot-toast'

import { ReactComponent as ActiveIcon } from '../../../assets/icons/icon_accepted.svg'
import { ReactComponent as UnactiveIcon } from '../../../assets/icons/icon_rejected.svg'
import { DataTableColumnHeader } from '../../../components/DataTable/ColumnHeader'
import ProtectedComponent from '../../../components/ProtectedComponent'
import { ICreator } from '../../../types/learn/creator.interface'
import formatId from '../../../utils/formatId'

import CreateOrUpdateCreator from './CreateOrUpdateCreator'
import DeleteCreator from './DeleteCreator'

export const columns: ColumnDef<ICreator>[] = [
  {
    accessorKey: 'id',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Id' />
    },
    cell: ({ row }) => {
      return (
        <button
          onClick={() => {
            navigator.clipboard.writeText(`${row.original.id}`)
            toast.success('ID Copied to clipboard')
          }}
        >
          {formatId(row.original.id)}
        </button>
      )
    }
  },
  {
    accessorKey: 'name',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Creator' />
    },
    cell: ({ row }) => {
      if (row.original.name) {
        return <p className='opacity-50'>{row.original.name}</p>
      } else {
        return <p className='opacity-50'>N/A</p>
      }
    }
  },
  {
    accessorKey: 'username',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='@user' />
    },
    cell: ({ row }) => {
      return (
        <button
          onClick={() => {
            navigator.clipboard.writeText(`@${row.original.username}`)
            toast.success('Username Copied to clipboard')
          }}
          className='opacity-50'
        >
          @{row.original.username}
        </button>
      )
    }
  },
  {
    accessorKey: 'status',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Visible' />
    },
    cell: ({ row }) => {
      return row.original.status ? <ActiveIcon /> : <UnactiveIcon />
    },
    enableSorting: false
  },
  {
    accessorKey: 'image_full',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Image' />
    },
    cell: ({ row }) => {
      return row.original.image_full ? (
        <div
          className='h-[46px] w-[24px] overflow-hidden rounded-[4px] bg-cover bg-center'
          style={{
            backgroundImage: `url(${row.original.image_full})`
          }}
        />
      ) : (
        <div className='h-[46px] w-[24px] rounded-[4px] bg-white/20' />
      )
    },
    enableSorting: false
  },
  {
    accessorKey: 'image',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Avatar' />
    },
    cell: ({ row }) => {
      return row.original.image ? (
        <div
          className='h-[30px] w-[30px] overflow-hidden rounded-full bg-cover bg-center'
          style={{
            backgroundImage: `url(${row.original.image})`
          }}
        />
      ) : (
        <div className='h-[30px] w-[30px] rounded-full bg-white/20' />
      )
    },
    enableSorting: false
  },
  {
    accessorKey: 'created_at',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Date Added' />
    },
    cell: ({ row }) => {
      return (
        <p className='opacity-50'>
          {new Date(row.original.created_at).toDateString()}
        </p>
      )
    }
  },
  {
    accessorKey: 'actions',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='' />
    },
    cell: ({ row }) => {
      return (
        <div className='flex w-fit flex-row gap-1 border-l-2 border-border pl-4'>
          <ProtectedComponent permissionSlug='learn_update'>
            <CreateOrUpdateCreator
              initial={row.original}
              url={`admin/learn/creators/${row.original.id}`}
            />
          </ProtectedComponent>
          <ProtectedComponent permissionSlug='learn_delete'>
            <DeleteCreator id={row.original.id} />
          </ProtectedComponent>
        </div>
      )
    },
    enableSorting: false
  }
]
