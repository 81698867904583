import { useMutation, useQueryClient } from '@tanstack/react-query'

import api from '../../api'
import { DeleteButton } from '../../components/Buttons/DeleteButton'

interface Props {
  id: string
}

const UnblockUser: React.FC<Props> = ({ id }) => {
  const queryClient = useQueryClient()

  const { mutate, isPending } = useMutation({
    mutationKey: ['unblock-kyc-for-user'],
    mutationFn: async (id: string) => {
      await api.put(`/admin/bank/verifications/${id}/unblock`)
    },
    onSuccess: () => {
      queryClient.invalidateQueries()
    }
  })

  return (
    <>
      <DeleteButton
        onDelete={() => mutate(id)}
        title='Unblock'
        question='Are you sure you want to unblock KYC for this user?'
        disabled={isPending}
      />
    </>
  )
}

export default UnblockUser
