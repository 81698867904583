import { ColumnDef } from '@tanstack/react-table'
import toast from 'react-hot-toast'

import { ReactComponent as ActiveIcon } from '../../../assets/icons/icon_accepted.svg'
import { ReactComponent as UnactiveIcon } from '../../../assets/icons/icon_rejected.svg'
import { DataTableColumnHeader } from '../../../components/DataTable/ColumnHeader'
import ProtectedComponent from '../../../components/ProtectedComponent'
import { IVideo } from '../../../types/learn/video.interface'
import formatId from '../../../utils/formatId'

import CreateOrUpdateVideo from './CreateOrUpdateVideo'
import DeleteVideo from './DeleteVideo'

const formatTime = (seconds: number) => {
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)
  const remainingSeconds = seconds % 60

  const formattedHours = hours.toString().padStart(2, '0')
  const formattedMinutes = minutes.toString().padStart(2, '0')
  const formattedSeconds = remainingSeconds.toString().padStart(2, '0')

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`
}

export const columns: ColumnDef<IVideo>[] = [
  {
    accessorKey: 'id',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Id' />
    },
    cell: ({ row }) => {
      return (
        <button
          onClick={() => {
            navigator.clipboard.writeText(`${row.original.id}`)
            toast.success('ID Copied to clipboard')
          }}
        >
          {formatId(row.original.id)}
        </button>
      )
    }
  },
  {
    accessorKey: 'video_thumbnail_url',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Image' />
    },
    cell: ({ row }) => {
      return row.original.video_thumbnail_url ? (
        <div
          className='h-[42px] w-[60px] bg-cover bg-center'
          style={{
            backgroundImage: `url(${row.original.video_thumbnail_url})`
          }}
        />
      ) : (
        <div className='h-[42px] w-[60px] bg-white/20' />
      )
    },
    enableSorting: false
  },
  {
    accessorKey: 'title',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Title' />
    },
    cell: ({ row }) => {
      return <p className='opacity-50'>{row.original.title}</p>
    }
  },
  {
    accessorKey: 'creators',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Creator' />
    },
    cell: ({ row }) => {
      return (
        <p
          className='max-w-60 text-xs opacity-50'
          style={{
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            WebkitLineClamp: 2
          }}
        >
          {row.original.creators.map(creator => creator.name).join(', ') ||
            'N/A'}
        </p>
      )
    },
    enableSorting: false
  },
  {
    accessorKey: 'category',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Category' />
    },
    cell: ({ row }) => {
      return (
        <p
          className='max-w-60 text-xs opacity-50'
          style={{
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            WebkitLineClamp: 2
          }}
        >
          {row.original.categories.map(category => category.name).join(', ') ||
            'N/A'}
        </p>
      )
    }
  },
  {
    accessorKey: 'duration',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Length' />
    },
    cell: ({ row }) => {
      const timeValue = formatTime(row.original.duration)
      return (
        <p title={timeValue} className='opacity-50'>
          {timeValue}
        </p>
      )
    }
  },
  {
    accessorKey: 'views',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Views' />
    },
    cell: ({ row }) => {
      return row.original.views.toLocaleString()
    }
  },
  {
    accessorKey: 'status',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Visible' />
    },
    cell: ({ row }) => {
      return row.original.status ? <ActiveIcon /> : <UnactiveIcon />
    },
    enableSorting: false
  },
  {
    accessorKey: 'actions',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='' />
    },
    cell: ({ row }) => {
      return (
        <div className='flex w-fit flex-row gap-1 border-l-2 border-border pl-4'>
          <ProtectedComponent permissionSlug='learn_update'>
            <CreateOrUpdateVideo
              initial={row.original}
              url={`admin/learn/videos/${row.original.id}`}
            />
          </ProtectedComponent>
          <ProtectedComponent permissionSlug='learn_delete'>
            <DeleteVideo id={row.original.id} />
          </ProtectedComponent>
        </div>
      )
    },
    enableSorting: false
  }
]
