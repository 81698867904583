import { ColumnDef } from '@tanstack/react-table'
import toast from 'react-hot-toast'

import { DataTableColumnHeader } from '../../components/DataTable/ColumnHeader'
import { IEarn } from '../../types/account/earn.interface'
import formatId from '../../utils/formatId'
import { formatInteger, msatsToSats } from '../../utils/utils'

export const columns: ColumnDef<IEarn>[] = [
  {
    accessorKey: 'id',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Id' />
    },
    cell: ({ row }) => {
      return (
        <button
          onClick={() => {
            navigator.clipboard.writeText(`${row.original.id}`)
            toast.success('ID Copied to clipboard')
          }}
        >
          {formatId(row.original.id)}
        </button>
      )
    }
  },
  {
    accessorKey: 'username',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='@user' />
    },
    cell: ({ row }) => {
      return (
        <button
          onClick={() => {
            navigator.clipboard.writeText(`@${row.original.username}`)
            toast.success('Username Copied to clipboard')
          }}
          className='opacity-50'
        >
          @{row.original.username}
        </button>
      )
    }
  },
  {
    accessorKey: 'email',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Email' />
    },
    cell: ({ row }) => {
      return (
        <button
          onClick={() => {
            navigator.clipboard.writeText(row.original.email)
            toast.success('Email copied to clipboard')
          }}
          className='opacity-50'
        >
          {row.original.email}
        </button>
      )
    }
  },
  {
    accessorKey: 'daily_earn_rate_msats',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='DER' />
    },
    cell: ({ row }) => {
      return (
        <p className='opacity-50'>
          {row.original.daily_earn_rate_msats
            ? formatInteger(
                String(msatsToSats(row.original.daily_earn_rate_msats))
              )
            : '0'}
        </p>
      )
    }
  },
  {
    accessorKey: 'accrued_balance_msats',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Future Pay...' />
    },
    cell: ({ row }) => {
      return (
        <p className='opacity-50'>
          {row.original.accrued_balance_msats
            ? formatInteger(
                String(msatsToSats(row.original.accrued_balance_msats))
              )
            : '0'}
        </p>
      )
    }
  },
  {
    accessorKey: 'streak_count',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Streak' />
    },
    cell: ({ row }) => {
      return (
        <p className='opacity-50'>
          {row.original.streak_count
            ? formatInteger(row.original.streak_count)
            : '0'}
        </p>
      )
    }
  },
  {
    accessorKey: 'earned_unclaimed_msats',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Earned' />
    },
    cell: ({ row }) => {
      return (
        <p className='opacity-50'>
          {row.original.earned_unclaimed_msats
            ? formatInteger(
                String(msatsToSats(row.original.earned_unclaimed_msats))
              )
            : '0'}
        </p>
      )
    }
  },
  {
    accessorKey: 'created_at',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Rank' />
    },
    cell: ({ row }) => {
      return <p className='opacity-50'>{row.original.rank}</p>
    }
  }
]
