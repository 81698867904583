'use client'

import { keepPreviousData, useQuery } from '@tanstack/react-query'
import {
  ColumnDef,
  PaginationState,
  SortingState,
  flexRender,
  getCoreRowModel,
  useReactTable
} from '@tanstack/react-table'
import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import api from '../../api'

import { ResultWithPagination } from './DataTable.types'
import { Input } from './Input'
import { DataTablePagination } from './Pagination'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from './Table'
import { DataTableViewOptions } from './ViewOptions'

interface Props<T> {
  url: string
  body?: unknown
  columns: ColumnDef<T>[]
  totalText?: string
  className?: string
  tableClassName?: string
  defaultSortBy?: string
  defaultSortOrder?: 'asc' | 'desc'
}

export function DataTable<T>({
  columns,
  url,
  body,
  totalText,
  className,
  tableClassName,
  defaultSortBy,
  defaultSortOrder
}: Props<T>) {
  const [searchParams, setSearchParams] = useSearchParams()

  const getPaginationState = (): PaginationState => ({
    pageIndex: Number(searchParams.get('pageIndex')) || 0,
    pageSize: Number(searchParams.get('pageSize')) || 50
  })

  const getSortingState = (): SortingState => {
    const sortBy = searchParams.get('sort_by') || defaultSortBy
    const sortOrder = searchParams.get('sort_order') || defaultSortOrder
    if (sortBy && sortOrder) {
      return [{ id: sortBy, desc: sortOrder === 'desc' }]
    }
    return []
  }

  const getSearchState = (): string => searchParams.get('search') || ''

  const [pagination, setPagination] =
    useState<PaginationState>(getPaginationState)
  const [sorting, setSorting] = useState<SortingState>(getSortingState)
  const [search, setSearch] = useState<string>(getSearchState)

  useEffect(() => {
    setSearchParams({
      pageIndex: pagination.pageIndex.toString(),
      pageSize: pagination.pageSize.toString(),
      sort_by: sorting.length ? sorting[0].id : '',
      sort_order: sorting.length ? (sorting[0].desc ? 'desc' : 'asc') : '',
      search: search
    })
  }, [pagination, sorting, search, setSearchParams])

  const { data, isLoading, isError } = useQuery({
    queryKey: ['data-table', url, pagination, sorting, search],
    queryFn: async () => {
      const resp = await api.get<ResultWithPagination<T>>(url, {
        params: {
          offset: pagination.pageIndex,
          limit: pagination.pageSize,
          sort_by: sorting.length ? sorting[0].id : undefined,
          sort_order: sorting.length
            ? sorting[0].desc
              ? 'desc'
              : 'asc'
            : undefined,
          search
        },
        data: body
      })

      return resp.data
    },
    refetchOnWindowFocus: false,
    placeholderData: keepPreviousData
  })
  const table = useReactTable({
    data: data?.result || [],
    columns,
    rowCount: data?.pagination?.total || 0,
    state: {
      pagination,
      sorting
    },
    initialState: {
      columnVisibility: {
        id: false,
        legal_name: false
      }
    },
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    manualPagination: true,
    manualSorting: true,
    getCoreRowModel: getCoreRowModel()
  })

  return (
    <div className={clsx('rounded-[32px] border bg-gray800', className)}>
      <div className='flex items-center justify-between px-4 py-2  md:py-4'>
        <Input
          placeholder={'Search'}
          value={search}
          onChange={e => {
            setSearch(e.target.value)
            setPagination({ ...pagination, pageIndex: 0 })
          }}
          className='max-w-[300px] !rounded-[32px] !py-5'
        />
        <DataTableViewOptions table={table} />
      </div>
      <Table
        className={clsx(
          'max-h-[calc(100vh-300px)] md:max-h-[calc(100vh-450px)]',
          {
            '!overflow-x-hidden': !data?.result?.length
          },
          tableClassName
        )}
      >
        <TableHeader>
          {table.getHeaderGroups().map(headerGroup => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map(header => {
                return (
                  <TableHead key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </TableHead>
                )
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {isLoading ? (
            <TableRow>
              <TableCell colSpan={columns.length}>
                <div className='flex items-center justify-center space-x-2'>
                  <span>Loading...</span>
                </div>
              </TableCell>
            </TableRow>
          ) : isError ? (
            <TableRow>
              <TableCell
                colSpan={columns.length}
                className='text-center font-bold'
              >
                Error loading data
              </TableCell>
            </TableRow>
          ) : table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map(row => (
              <TableRow
                key={row.id}
                data-state={row.getIsSelected() && 'selected'}
              >
                {row.getVisibleCells().map(cell => (
                  <TableCell
                    key={cell.id}
                    className={clsx({
                      '!m-0 !ml-auto !w-[180px] place-self-end self-end !p-0':
                        !!cell.id.includes('actions')
                    })}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length} className='h-24'>
                <p className='!max-w-[75vw]  text-center text-lg font-bold'>
                  No data found
                </p>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <div className='flex w-full items-center justify-between gap-4 p-2 md:p-4'>
        <div className='text-sm font-medium text-white'>
          {data?.pagination?.total} {totalText || 'Total'}
        </div>
        <DataTablePagination table={table} />
      </div>
    </div>
  )
}
