import clsx from 'clsx'
import React from 'react'

import { Button } from '../Button'

interface EditButtonProps {
  title: string
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  className?: string
  disabled?: boolean
}

export const EditButton = ({
  title,
  onClick,
  className,
  disabled
}: EditButtonProps) => {
  return (
    <Button
      disabled={disabled}
      title={title}
      onClick={onClick}
      className={clsx(
        'justify-center bg-white/10 px-[20px] text-center text-[12px] font-bold text-white transition-all',
        className
      )}
    />
  )
}
