import clsx from 'clsx'
import { ReactNode } from 'react'

export interface SectionOption {
  label: string
  rightContent?: string | ReactNode
}
interface SectionProps {
  title: string
  options: SectionOption[]
}

export const Section = ({ title, options }: SectionProps) => {
  return (
    <div className='flex flex-col'>
      <p className='text-[20px] font-bold tracking-normal text-white'>
        {title}
      </p>
      <div className='my-[20px] flex flex-col'>
        {options.map((option, index) => (
          <div
            key={index}
            className={clsx(
              'flex flex-row items-center justify-between gap-[8px]',
              index !== options.length - 1 && 'mb-[15px]'
            )}
          >
            <p className='text-[14px] font-semibold text-white/50'>
              {option.label}
            </p>
            {typeof option.rightContent === 'string' ? (
              <p
                title={option.rightContent}
                className='long-text text-[14px] font-semibold text-white'
              >
                {option.rightContent}
              </p>
            ) : (
              option.rightContent
            )}
          </div>
        ))}
      </div>
    </div>
  )
}
