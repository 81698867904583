import clsx from 'clsx'

import {
  CategoriesData,
  CreatorsData,
  ServiceData,
  UsersData,
  VideosData
} from '../types/old-table/old-table.types'
import { shortenString } from '../utils/utils'

import { FormButton } from './FormButton'
import { Popup } from './Popup'

type DataTypeMap = {
  users: UsersData[]
  creators: CreatorsData[]
  categories: CategoriesData[]
  videos: VideosData[]
  services: ServiceData[]
}

type AdminTableProps<
  T extends 'users' | 'creators' | 'categories' | 'videos' | 'services'
> = {
  type?: T
  header?: string
  rawData?: DataTypeMap[T]
  onEdit?: (_: string) => void
  onDelete?: (_: string) => void
  onView?: (_: string) => void
}

export function AdminTable({
  type,
  header,
  rawData,
  onEdit,
  onDelete,
  onView
}: AdminTableProps<
  'users' | 'creators' | 'categories' | 'videos' | 'services'
>) {
  let columns = []

  if (!type) return null

  // TODO: can do something better here, maybe pass in columns as a prop
  switch (type) {
    case 'users':
      columns = [
        'ID',
        'Name',
        '@user',
        'Email',
        'Avatar',
        'Date Joined',
        'KYC'
        // "Streak",
        // "Earned",
        // "Rank",
      ]
      break

    case 'creators':
      columns = ['ID', 'Creator', 'Username', 'Visible', 'Image', 'Avatar']
      break

    case 'categories':
      columns = ['ID', 'Category', 'Amount', 'Visible']
      break

    case 'videos':
      columns = [
        'ID',
        'Image',
        'Title',
        'Creator',
        'Length',
        'Views',
        'Visible'
      ]
      break

    case 'services':
      columns = [
        'Name',
        'Instance ID',
        'State',
        'Type',
        'Status',
        'Alarms',
        'Zone',
        'Public IPv4 DNS'
      ]
      break

    default:
      return null
  }

  const numberOfRows = rawData?.length

  return (
    <div className='w-screen-excluding-sidebar overflow-x-auto rounded-[30px] border border-white border-opacity-10 bg-[#17181A]'>
      <div
        className='w-auto bg-[#17181A] px-7 py-3 text-lg font-bold text-white
          text-opacity-50'
      >
        {header}
      </div>
      <table className='w-full divide-y divide-white divide-opacity-10 text-sm'>
        <thead className='bg-[#17181A]'>
          <tr className='border-y border-white border-opacity-10'>
            {columns.map((column, index) => (
              <th
                key={index}
                scope='col'
                className={clsx(
                  'py-3 text-left text-lg font-bold tracking-wider text-white',
                  {
                    'pl-7': index === 0,
                    'px-7': index !== 0
                  }
                )}
              >
                {column}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className='bg-[#17181A]'>
          {rawData?.map((item: any, index) => {
            return (
              <tr
                key={item.id}
                className={`${
                  index === numberOfRows! - 1 ? '' : 'border-b'
                } border-white border-opacity-10 hover:bg-[#1E1F21]`}
              >
                {type === 'users' ? (
                  <>
                    <td className='whitespace-nowrap px-7 py-4 text-sm font-medium text-white text-opacity-50'>
                      {item.id}
                    </td>
                    <td className='whitespace-nowrap px-7 py-4 text-sm font-medium text-white'>
                      {shortenString(item.name, 20)}
                    </td>
                    <td className='whitespace-nowrap px-7 py-4 text-sm font-medium text-white text-opacity-50'>
                      {shortenString(item.user, 20)}
                    </td>
                    <td className='whitespace-nowrap px-7 py-4 text-sm font-medium text-white text-opacity-50'>
                      {shortenString(item.email, 20)}
                    </td>
                    <td className='whitespace-nowrap px-7 py-4 text-sm font-medium text-white text-opacity-50'>
                      {item.avatar ? (
                        <img
                          className='h-8 w-8 rounded-full'
                          src={item.avatar}
                          alt='avatar'
                        />
                      ) : null}
                    </td>

                    <td className='whitespace-nowrap px-7 py-4 text-sm font-medium text-white text-opacity-50 '>
                      {item.doj}
                    </td>
                    <td className='whitespace-nowrap px-7 py-4 text-sm font-medium text-white text-opacity-50'>
                      <div
                        className={`h-4 w-4 rounded-full ${item.kyc ? 'bg-green-500' : 'bg-red-700'}`}
                      />
                    </td>
                    {/*
                    <td className="px-7 py-4 whitespace-nowrap text-sm font-medium text-white text-opacity-50">
                      {item.streak}
                    </td>
                    <td className="px-7 py-4 whitespace-nowrap text-sm font-medium text-white text-opacity-50">
                      {item.earned}
                    </td>
                    <td className="px-7 py-4 whitespace-nowrap text-sm font-medium text-white text-opacity-50">
                      {item.rank}
                    </td>
                    */}
                  </>
                ) : null}
                {type === 'creators' ? (
                  <>
                    <td className='whitespace-nowrap px-7 py-4 text-sm font-medium text-white text-opacity-50'>
                      {item.id}
                    </td>
                    <td className='whitespace-nowrap px-7 py-4 text-sm font-medium text-white'>
                      {shortenString(item.creator, 20)}
                    </td>
                    <td className='whitespace-nowrap px-7 py-4 text-sm font-medium text-white text-opacity-50'>
                      {shortenString(item.username, 20)}
                    </td>
                    <td className='whitespace-nowrap px-7 py-4 text-sm font-medium text-white text-opacity-50'>
                      <div
                        className={`h-4 w-4 rounded-full ${item.visible ? 'bg-green-500' : 'bg-red-700'}`}
                      />
                    </td>
                    <td className='whitespace-nowrap px-7 py-4 text-sm font-medium text-white text-opacity-50'>
                      {item.image ? (
                        <img
                          className='h-8 w-8 rounded-full'
                          src={item.image}
                          alt='image'
                        />
                      ) : null}
                    </td>
                    <td className='whitespace-nowrap px-7 py-4 text-sm font-medium text-white text-opacity-50'>
                      {item.avatar ? (
                        <img
                          className='h-8 w-8 rounded-full'
                          src={item.avatar}
                          alt='avatar'
                        />
                      ) : null}
                    </td>
                  </>
                ) : null}
                {type === 'categories' ? (
                  <>
                    <td className='whitespace-nowrap px-7 py-4 text-sm font-medium text-white text-opacity-50'>
                      {item.id}
                    </td>
                    <td className='whitespace-nowrap px-7 py-4 text-sm font-medium text-white'>
                      {item.category}
                    </td>
                    <td className='whitespace-nowrap px-7 py-4 text-sm font-medium text-white text-opacity-50'>
                      {item.amount}
                    </td>
                    <td className='whitespace-nowrap px-7 py-4 text-sm font-medium text-white text-opacity-50'>
                      <div
                        className={`h-4 w-4 rounded-full ${item.visible ? 'bg-green-500' : 'bg-red-700'}`}
                      />
                    </td>
                  </>
                ) : null}
                {type === 'videos' ? (
                  <>
                    <td className='whitespace-nowrap px-7 py-4 text-sm font-medium text-white text-opacity-50'>
                      {item.id}
                    </td>
                    <td className='whitespace-nowrap px-7 py-4 text-sm font-medium text-white text-opacity-50'>
                      {item.image ? (
                        <img
                          className='h-8 w-8 rounded-full'
                          src={item.image}
                          alt='image'
                        />
                      ) : null}
                    </td>
                    <td className='whitespace-nowrap px-7 py-4 text-sm font-medium text-white'>
                      {shortenString(item.title, 20)}
                    </td>
                    <td className='whitespace-nowrap px-7 py-4 text-sm font-medium text-white'>
                      {shortenString(item.creator, 20)}
                    </td>
                    <td className='whitespace-nowrap px-7 py-4 text-sm font-medium text-white'>
                      {item.length}
                    </td>
                    <td className='whitespace-nowrap px-7 py-4 text-sm font-medium text-white'>
                      {item.views}
                    </td>
                    <td className='whitespace-nowrap px-7 py-4 text-sm font-medium text-white text-opacity-50'>
                      <div
                        className={`h-4 w-4 rounded-full ${item.visible ? 'bg-green-500' : 'bg-red-700'}`}
                      />
                    </td>
                  </>
                ) : null}
                {type === 'services' ? (
                  <>
                    <td className='whitespace-nowrap px-7 py-4 text-sm font-medium text-white'>
                      {shortenString(item.name, 25)}
                    </td>
                    <td className='whitespace-nowrap px-7 py-4 text-sm font-medium text-[#4676B2]'>
                      {shortenString(item.instanceId, 20)}
                    </td>
                    <td className='whitespace-nowrap px-7 py-4 text-sm font-medium text-white'>
                      {item.state === 'running' ? (
                        <div className='flex justify-normal'>
                          <div className='mr-2 h-4 w-4 self-center rounded-full bg-green-500' />
                          <div className='self-center text-green-500'>
                            {item.state}
                          </div>
                        </div>
                      ) : item.state === 'stopped' ? (
                        <div className='flex justify-normal'>
                          <div className='mr-2 h-4 w-4 self-center rounded-full bg-red-700' />
                          <div className='h-4 w-4 rounded-full bg-red-700' />
                        </div>
                      ) : (
                        <></>
                      )}
                    </td>
                    <td className='whitespace-nowrap px-7 py-4 text-sm font-medium text-white text-opacity-50'>
                      {item.type}
                    </td>
                    <td className='whitespace-nowrap px-7 py-4 text-sm font-medium text-white'>
                      {item.status === 'healthy' ? (
                        <div className='flex justify-normal'>
                          <div className='mr-1 h-4 w-4 rounded-full bg-green-500' />
                          <div className='h-4 w-4 rounded-full bg-green-500' />
                        </div>
                      ) : (
                        <div className='flex justify-normal'>
                          <div className='mr-1 h-4 w-4 rounded-full bg-red-700' />
                          <div className='h-4 w-4 rounded-full bg-red-700' />
                        </div>
                      )}
                    </td>
                    <td className='whitespace-nowrap px-7 py-4 text-sm font-medium text-white text-opacity-50'>
                      {item.alarms}
                    </td>
                    <td className='whitespace-nowrap px-7 py-4 text-sm font-medium text-white text-opacity-50'>
                      {shortenString(item.zone, 20)}
                    </td>
                    <td className='whitespace-nowrap px-7 py-4 text-sm font-medium text-white text-opacity-50'>
                      {shortenString(item.publicIpv4Dns, 30)}
                    </td>
                  </>
                ) : null}
                <div className='flex justify-end whitespace-nowrap'>
                  <td className='border-l border-white border-opacity-10 px-7 py-4 text-right text-sm font-medium'>
                    {onView && (
                      <button
                        onClick={() => onView(item.id)}
                        className='mr-3 inline-flex items-center rounded-3xl bg-white bg-opacity-10 px-4 py-2 text-white hover:bg-opacity-20'
                      >
                        View
                      </button>
                    )}
                    {onEdit && (
                      <button
                        onClick={() => onEdit(item.id)}
                        className='inline-flex items-center rounded-3xl bg-white bg-opacity-10 px-4 py-2 text-white hover:bg-opacity-20'
                      >
                        Edit
                      </button>
                    )}
                    {onDelete && (
                      <Popup
                        content={
                          <div className='mt-[30px] flex gap-[10px]'>
                            <FormButton
                              type='button'
                              title='Yes'
                              stopPropagation={false}
                              preventDefault={false}
                              className='bg-orange500/10 text-orange500'
                              onClick={() => {
                                onDelete(item.id)
                              }}
                            />
                            <FormButton
                              type='button'
                              title='No'
                              stopPropagation={false}
                              preventDefault={false}
                              className='bg-white/10 text-white'
                            />
                          </div>
                        }
                        title='Are you sure you want to delete?'
                        trigger='click'
                        verticalPosition='bottom'
                        horizontalPosition='end'
                        className='mt-[4px]'
                      >
                        <button className='ml-3 inline-flex items-center rounded-3xl bg-[#F87249] bg-opacity-10 px-4 py-2 text-[#F87249] hover:bg-opacity-20'>
                          Delete
                        </button>
                      </Popup>
                    )}
                  </td>
                </div>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
