import { ColumnDef } from '@tanstack/react-table'
import { useNavigate } from 'react-router-dom'

import { DataTableColumnHeader } from '../../components/DataTable/ColumnHeader'
import ProtectedComponent from '../../components/ProtectedComponent'
import { IAccountGroup } from '../../types/account-groups/account-group.interface'
import formatId from '../../utils/formatId'
import { EditButton } from '../Users/Buttons/EditButton'

import DeleteAccountGroup from './DeleteAccountGroup'

export const columns: ColumnDef<IAccountGroup>[] = [
  {
    accessorKey: 'id',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Id' />
    },
    cell: ({ row }) => {
      return formatId(row.original.id)
    },
    enableSorting: false
  },
  {
    accessorKey: 'name',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Name' />
    },
    cell: ({ row }) => {
      return <p className='text-white opacity-50'>{row.original.name}</p>
    },
    enableSorting: false
  },
  {
    accessorKey: 'accounts',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Accounts #' />
    },
    cell: ({ row }) => {
      return (
        <p className='text-white opacity-50'>{row.original.accounts_count}</p>
      )
    }
  },
  {
    accessorKey: 'actions',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Actions' />
    },
    cell: ({ row }) => {
      const navigate = useNavigate()
      return (
        <div className='flex w-fit flex-row gap-1 border-l-2 border-border pl-4'>
          <EditButton
            title='View'
            onClick={() => {
              navigate(`/account-groups/${row.original.id}`, {
                replace: true
              })
            }}
          />
          <ProtectedComponent permissionSlug='account_groups_delete'>
            <DeleteAccountGroup id={row.original.id} />
          </ProtectedComponent>
        </div>
      )
    },
    enableSorting: false
  }
]
