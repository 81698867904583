export function StopCircle({ className }: { className: string }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 16 16'
      className={className}
    >
      <path
        fillRule='evenodd'
        d='M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14ZM6.5 5.5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1h-3Z'
        clipRule='evenodd'
        fill='currentColor'
      />
    </svg>
  )
}
