import { Helmet } from 'react-helmet'

import { DataTable } from '../../components/DataTable'
import ProtectedComponent from '../../components/ProtectedComponent'

import CreateEDDRequest from './CreateEDDRequest'
import { columns } from './columns'

const BankEddRequests: React.FC = () => {
  return (
    <ProtectedComponent permissionSlug='edd_requests_view' isPage>
      <Helmet>
        <title>STBA Admin - Bank Edd Requests</title>
      </Helmet>
      <section className='gap- flex w-full flex-col p-2 md:gap-4 md:px-4 md:py-6 lg:px-8 lg:py-10'>
        <section className='flex w-full flex-wrap justify-between gap-2 border-b border-white border-opacity-10 pb-7'>
          <h1 className='text-2xl font-bold text-white md:text-3xl'>
            EDD Requests
          </h1>
          <ProtectedComponent permissionSlug='edd_requests_create'>
            <CreateEDDRequest />
          </ProtectedComponent>
        </section>
        <DataTable url='admin/bank/edd' columns={columns} />
      </section>
    </ProtectedComponent>
  )
}

export default BankEddRequests
