import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

export const tabs: Record<string, string> = {
  '/organization/members': 'Team Members',
  '/organization/roles': 'Roles'
}

const Tabs: React.FC = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [selectedTab, setSelectedTab] = useState<string>(
    tabs?.[location.pathname] ? location.pathname : ''
  )

  useEffect(() => {
    setSelectedTab(location.pathname)
  }, [location.pathname, navigate])

  const tabOnClick = (tab: string) => {
    return () => {
      setSelectedTab(tab)
      navigate(tab)
    }
  }

  return (
    <div className='mb-6 flex'>
      {Object.entries(tabs).map(([tab, label], i) => (
        <div
          key={i}
          onClick={tabOnClick(tab)}
          className={clsx(
            'cursor-pointer select-none pr-5 text-3xl font-bold',
            {
              'text-orange550': tab === selectedTab,
              'text-white text-opacity-50 hover:text-opacity-80 active:text-opacity-100':
                !(tab === selectedTab)
            }
          )}
        >
          {label}
        </div>
      ))}
    </div>
  )
}

export default Tabs
