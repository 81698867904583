import { ColumnDef } from '@tanstack/react-table'

import { DataTableColumnHeader } from '../../../components/DataTable/ColumnHeader'
import ProtectedComponent from '../../../components/ProtectedComponent'
import { IRole } from '../../../types/roles/role.interface'

import CreateOrUpdateRole from './CreateOrUpdateRole'
import DeleteRole from './DeleteRole'

export const columns: ColumnDef<IRole>[] = [
  {
    accessorKey: 'name',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Name' />
    },
    cell: ({ row }) => {
      if (row.original.name) {
        return <p className='opacity-50'>{row.original.name}</p>
      } else {
        return <p className='opacity-50'>N/A</p>
      }
    }
  },
  {
    accessorKey: 'description',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Description' />
    },
    cell: ({ row }) => {
      if (row.original.description) {
        return <p className='opacity-50'>{row.original.description}</p>
      } else {
        return <p className='opacity-50'>N/A</p>
      }
    }
  },
  {
    accessorKey: 'roles_count',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Count' />
    },
    cell: ({ row }) => {
      return row.original.administrators.length
    }
  },
  {
    accessorKey: 'actions',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='' />
    },
    cell: ({ row }) => {
      if (row.original.is_super_role) return null
      return (
        <div className='flex w-fit flex-row gap-1 border-l-2 border-border pl-4'>
          <ProtectedComponent permissionSlug='roles_update'>
            <CreateOrUpdateRole initial={row.original} />
          </ProtectedComponent>
          <ProtectedComponent permissionSlug='roles_delete'>
            <DeleteRole id={row.original.id} />
          </ProtectedComponent>
        </div>
      )
    },
    enableSorting: false
  }
]
