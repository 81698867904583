import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'

import api from '../../api'
import { AddButton } from '../../components/Buttons/AddButton'
import { FormButton } from '../../components/FormButton'
import { Input } from '../../components/Input'
import { Loader } from '../../components/Loader'
import { ErrorMessage } from '../../components/Message/ErrorMessage'
import { Modal } from '../../components/Modal'
import { INotificationCreate } from '../../types/notifications/notification.interface'

import { UsersAutocomplete } from './UsersAutocomplete'

export const SendNotification = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const queryClient = useQueryClient()

  const { mutate, isPending } = useMutation({
    mutationKey: ['sendNotification'],
    mutationFn: async (data: INotificationCreate) => {
      const resp = await api.post(
        data.receiver
          ? `/admin/notifications/send/${data.receiver.id || ''}`
          : `/admin/notifications/send-to-all`,
        {
          ...data,
          receiver: data.receiver ? data.receiver.id : null,
          type: 'message',
          data: '{}'
        }
      )

      return resp.data
    },
    onSuccess: async () => {
      reset()
      queryClient.refetchQueries()
      setIsOpen(false)
      toast.success('Notification successfully sent!', {
        id: 'send-notification'
      })
    },
    onError: error => {
      toast.error(error.message, {
        id: 'send-notification'
      })
    }
  })

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm<INotificationCreate>({
    defaultValues: {
      title: '',
      body: '',
      data: '',
      receiver: null
    }
  })

  const onSubmit = (data: INotificationCreate) => {
    mutate(data)
  }

  return (
    <>
      <AddButton onClick={() => setIsOpen(true)} title='Send notification' />
      <Modal visible={isOpen} onClose={() => setIsOpen(false)} overflow={false}>
        <Loader loading={isPending} type='absolute' />
        <Modal.Header
          title='Create notification'
          closable
          className='mb-[30px]'
        />
        <Modal.Content>
          <form className='flex w-full flex-col gap-5'>
            <div>
              <Controller
                name='title'
                control={control}
                render={({ field }) => {
                  return (
                    <Input
                      className='bg-gray900 text-[16px] font-bold'
                      type='text'
                      name='notification'
                      placeholder='Write notification title'
                      value={field.value}
                      onChange={field.onChange}
                    />
                  )
                }}
              />
              {errors.title?.message && (
                <ErrorMessage message={errors.title.message} />
              )}
            </div>
            <div>
              <Controller
                name='body'
                control={control}
                render={({ field }) => {
                  return (
                    <Input
                      className='bg-gray900 text-[16px] font-bold'
                      type='text'
                      name='notification'
                      placeholder='Notification body'
                      value={field.value}
                      onChange={field.onChange}
                    />
                  )
                }}
              />
              {errors.body?.message && (
                <ErrorMessage message={errors.body.message} />
              )}
            </div>
            <div className='mb-[100px]'>
              <Controller
                name='receiver'
                control={control}
                render={({ field }) => {
                  return (
                    <UsersAutocomplete
                      selectedUser={field.value}
                      onSelect={option => {
                        field.onChange(option)
                      }}
                    />
                  )
                }}
              />
              {errors.receiver?.message && (
                <ErrorMessage message={errors.receiver.message} />
              )}
            </div>
            <FormButton
              type='submit'
              title='Push'
              stopPropagation
              preventDefault
              className='bg-orange550 text-white'
              onClick={handleSubmit(onSubmit)}
            />
          </form>
        </Modal.Content>
      </Modal>
    </>
  )
}
