import clsx from 'clsx'
import { ReactNode } from 'react'

import { SelectOption } from './index'

interface OptionProps<T> {
  option: SelectOption<T>
  getLabel?: (option: SelectOption<T>) => any
  startContent?: ReactNode
  endContent?: ReactNode
  onClick?: () => void
  hover?: boolean
  labelClassName?: string
  selected?: boolean
}

export const Option = <T,>({
  startContent,
  getLabel,
  endContent,
  option,
  onClick,
  hover,
  selected,
  labelClassName
}: OptionProps<T>) => {
  return (
    <div
      onClick={e => {
        e.stopPropagation()
        onClick && onClick()
      }}
      className={clsx(
        'flex cursor-pointer items-center bg-white/0 p-[20px] transition-all duration-300',
        {
          'hover:bg-white/20': hover,
          'bg-white/20': selected
        }
      )}
    >
      {startContent}
      <span
        className={clsx('text-[16px] font-bold text-white', labelClassName)}
      >
        {typeof option?.value === 'string'
          ? option.value
          : getLabel && getLabel(option)}
      </span>
      <div className='self-end'>{endContent}</div>
    </div>
  )
}
