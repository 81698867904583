import BigNumber from 'bignumber.js'

export const shortenString = (str: string, len: number) => {
  if (str.length <= len) return str
  return str.slice(0, len) + '...'
}

export const millisatoshisToBTC = (millisatoshis: BigNumber): BigNumber => {
  const millisatsPerBitcoin = new BigNumber(100000000000)

  return millisatoshis.div(millisatsPerBitcoin)
}

export const formatNumber = (num: string | undefined, empty?: string) => {
  if (!num) {
    return empty || ''
  }
  const formattedNumber = new BigNumber(num)
  return formattedNumber.toFormat(2)
}

export const formatInteger = (num: string | undefined, empty?: string) => {
  if (!num) {
    return empty || ''
  }

  const formattedNumber = new BigNumber(num)
  return formattedNumber.toFormat(0)
}

export const msatsToSats = (num: string | undefined, empty?: string) => {
  if (!num) {
    return empty || ''
  }

  const mstats = new BigNumber(num)
  const one = new BigNumber(1000)
  return mstats.div(one).integerValue(BigNumber.ROUND_FLOOR)
}

export const omitKeys = <T, Keys extends keyof T>(obj: T, keys: Keys[]) => {
  const clone = { ...obj }
  keys.forEach(key => {
    delete clone[key]
  })

  return clone as Omit<T, Keys>
}
