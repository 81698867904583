'use client'

import { useQuery } from '@tanstack/react-query'
import { Helmet } from 'react-helmet'

import api from '../../api'
import { DataTable } from '../../components/DataTable'

import UserMetricItem from './MetricItem'
import { columns } from './columns'

export function Users() {
  const { data, isLoading, isError } = useQuery({
    queryKey: ['users-metrics'],
    queryFn: async () => {
      const resp = await api.get<{
        totalUsers: number
        totalUsersWithAvatar: {
          count: number
          percentage: number
        }
        totalUsersWithKyc: {
          count: number
          percentage: number
        }
        totalUsersWithSatoshiPlus: {
          count: number
          percentage: number
        }
      }>('/admin/users/metrics')

      return resp.data
    }
  })

  return (
    <>
      <Helmet>
        <title>STBA Admin - Users</title>
      </Helmet>
      <section className='flex w-full flex-col gap-2 p-2 md:gap-4 md:px-4 md:py-6 lg:px-8 lg:py-10'>
        <h1 className='border-b border-white border-opacity-10 pb-7 text-2xl font-bold text-white md:text-3xl'>
          Users
        </h1>
        <div className='mt-2 grid grid-cols-1 gap-1 md:grid-cols-2 md:gap-2 xl:grid-cols-4 xl:gap-6'>
          <UserMetricItem
            label='Has avatar'
            count={data?.totalUsersWithAvatar.count || 0}
            percentage={data?.totalUsersWithAvatar.percentage || 0}
            isLoading={isLoading}
            error={isError ? 'Failed to fetch users metrics' : undefined}
          />
          <UserMetricItem
            label="Is KYC'd"
            count={data?.totalUsersWithKyc.count || 0}
            percentage={data?.totalUsersWithKyc.percentage || 0}
            isLoading={isLoading}
            error={isError ? 'Failed to fetch users metrics' : undefined}
          />
          <UserMetricItem
            label='Has Satoshi+'
            count={data?.totalUsersWithSatoshiPlus.count || 0}
            percentage={data?.totalUsersWithSatoshiPlus.percentage || 0}
            isLoading={isLoading}
            error={isError ? 'Failed to fetch users metrics' : undefined}
          />
        </div>
        <DataTable
          columns={columns}
          url='/admin/users'
          totalText='users'
          className='mt-2'
          tableClassName='md:!max-h-[calc(100vh-550px)]'
        />
      </section>
    </>
  )
}
