import BigNumber from 'bignumber.js'

import {
  ExchangeDataInterface,
  FlashDataInterface,
  IUserMetrics,
  StarterDataInterface
} from '../../types/metrics/metrics.interface'
import {
  formatInteger,
  formatNumber,
  millisatoshisToBTC
} from '../../utils/utils'

export const EMPTY_NUMBER = ' -'

const mstasToBtcAndFormat = (num: string | undefined) => {
  if (!num) {
    return EMPTY_NUMBER
  }

  const val = millisatoshisToBTC(new BigNumber(num))
  return val >= BigNumber(9999999) ? val.toFormat(0) : val.toFormat(3)
}

const formatUserData = (data?: IUserMetrics) => {
  if (!data) {
    return {
      dailyActive: {
        label: EMPTY_NUMBER,
        description: 'Daily Active'
      },
      weeklyActive: {
        label: EMPTY_NUMBER,
        description: 'Weekly Active'
      },
      monthlyActive: {
        label: EMPTY_NUMBER,
        description: 'Monthly Active'
      },
      totalAccounts: {
        label: EMPTY_NUMBER,
        description: 'Total Accounts'
      },
      satoshiPlus: {
        label: EMPTY_NUMBER,
        description: 'Satoshi+'
      },
      referredUsers: {
        label: EMPTY_NUMBER,
        description: 'Referred Users'
      },
      waitlistEmails: {
        label: EMPTY_NUMBER,
        description: 'Waitlist Emails'
      },
      totalDownloads: {
        label: EMPTY_NUMBER,
        description: 'Total Downloads'
      }
    }
  }

  return {
    dailyActive: {
      label: formatInteger(data?.users_daily_active, EMPTY_NUMBER),
      description: 'Daily Active'
    },
    weeklyActive: {
      label: formatInteger(data?.users_weekly_active, EMPTY_NUMBER),
      description: 'Weekly Active'
    },
    monthlyActive: {
      label: formatInteger(data?.users_monthly_active, EMPTY_NUMBER),
      description: 'Monthly Active'
    },
    totalAccounts: {
      label: formatInteger(data?.users_total, EMPTY_NUMBER),
      description: 'Total Accounts'
    },
    satoshiPlus: {
      label: formatInteger(data?.users_satoshi_plus, EMPTY_NUMBER),
      description: 'Satoshi+'
    },
    referredUsers: {
      label: formatInteger(data?.users_referred, EMPTY_NUMBER),
      description: 'Referred Users'
    },
    waitlistEmails: {
      label: formatInteger(data?.users_waitlisted_emails, EMPTY_NUMBER),
      description: 'Waitlist Emails'
    },
    totalDownloads: {
      label: formatInteger(data?.users_total_downloads, EMPTY_NUMBER),
      description: 'Total Downloads'
    }
  }
}

const formatExchangeData = (inputData: ExchangeDataInterface | undefined) => {
  const data = inputData?.result

  return {
    bitcoinUnderCustody: {
      description: 'Bitcoin Under Custody',
      tabs: [
        {
          key: 'current',
          label: 'Current',
          value: mstasToBtcAndFormat(data?.custodied_msats)
        }
      ]
    },
    dollarsUnderCustody: {
      description: 'Dollars Under Custody',
      tabs: [
        {
          key: 'current',
          label: 'Current',
          value: `$${formatNumber(data?.custodied_usd, EMPTY_NUMBER)}`
        }
      ]
    },
    buyVolume: {
      description: 'Buy Volume',
      tabs: [
        {
          key: 'day',
          label: '24hr',
          value: `$${formatNumber(data?.buy_volume_usd_24h, EMPTY_NUMBER)}`
        },
        {
          key: 'month',
          label: '30d',
          value: `$${formatNumber(data?.buy_volume_usd_30d, EMPTY_NUMBER)}`
        },
        {
          key: 'all',
          label: 'All',
          value: `$${formatNumber(data?.buy_volume_usd_all, EMPTY_NUMBER)}`
        }
      ]
    },
    sellVolume: {
      description: 'Sell Volume',
      tabs: [
        {
          key: 'day',
          label: '24hr',
          value: `$${formatNumber(data?.sell_volume_usd_24h, EMPTY_NUMBER)}`
        },
        {
          key: 'month',
          label: '30d',
          value: `$${formatNumber(data?.sell_volume_usd_30d, EMPTY_NUMBER)}`
        },
        {
          key: 'all',
          label: 'All',
          value: `$${formatNumber(data?.sell_volume_usd_all, EMPTY_NUMBER)}`
        }
      ]
    }
  }
}

const formatFlashData = (inputData: FlashDataInterface | undefined) => {
  const data = inputData?.result

  return {
    totalLightningBalances: {
      label: `$${formatNumber(data?.total_lightning_balance_usd, EMPTY_NUMBER)}`,
      description: `${mstasToBtcAndFormat(data?.total_lightning_balance_msats)} BTC`,
      subDescription: 'Total Lightning Balances'
    },
    volumeReceived: {
      label: `$${formatNumber(data?.volume_received_usd, EMPTY_NUMBER)}`,
      description: `${mstasToBtcAndFormat(data?.volume_received_msats)} BTC`,
      subDescription: 'Volume Received'
    },
    volumeSent: {
      label: `$${formatNumber(data?.volume_sent_usd, EMPTY_NUMBER)}`,
      description: `${mstasToBtcAndFormat(data?.volume_sent_msats)} BTC`,
      subDescription: 'Volume Sent'
    },
    paymentsMade: {
      description: 'Payments Made',
      tabs: [
        {
          key: 'day',
          label: '24hr',
          value: `${formatInteger(data?.payments_made_24h, EMPTY_NUMBER)}`
        },
        {
          key: 'month',
          label: '30d',
          value: `${formatInteger(data?.payments_made_30d, EMPTY_NUMBER)}`
        },
        {
          key: 'all',
          label: 'All',
          value: `${formatInteger(data?.payments_made_all, EMPTY_NUMBER)}`
        }
      ]
    },
    channelsOpen: {
      description: 'Channels Open',
      tabs: [
        {
          key: 'day',
          label: '24hr',
          value: `${formatInteger(data?.channels_opened_24h, EMPTY_NUMBER)}`
        },
        {
          key: 'month',
          label: '30d',
          value: `${formatInteger(data?.channels_opened_30d, EMPTY_NUMBER)}`
        },
        {
          key: 'all',
          label: 'All',
          value: `${formatInteger(data?.channels_opened_all, EMPTY_NUMBER)}`
        }
      ]
    }
  }
}

const formatStarterData = (inputData: StarterDataInterface | undefined) => {
  const data = inputData?.result

  return {
    totalBalance: {
      label: `$${formatNumber(data?.starter_total_balance_usd, EMPTY_NUMBER)}`,
      description: `${mstasToBtcAndFormat(data?.starter_total_balance_msats)} BTC`,
      subDescription: 'Total Balance'
    },
    volumeReceived: {
      label: `$${formatNumber(data?.starter_volume_received_usd, EMPTY_NUMBER)}`,
      description: `${mstasToBtcAndFormat(data?.starter_volume_received_msats)} BTC`,
      subDescription: 'Volume Received'
    },
    volumeSent: {
      label: `$${formatNumber(data?.starter_volume_sent_usd, EMPTY_NUMBER)}`,
      description: `${mstasToBtcAndFormat(data?.starter_volume_sent_msats)} BTC`,
      subDescription: 'Volume Sent'
    },
    paymentsMade: {
      description: 'Payments Made',
      tabs: [
        {
          key: 'day',
          label: '24hr',
          value: `${formatInteger(data?.starter_payments_made_24h, EMPTY_NUMBER)}`
        },
        {
          key: 'month',
          label: '30d',
          value: `${formatInteger(data?.starter_payments_made_30d, EMPTY_NUMBER)}`
        },
        {
          key: 'all',
          label: 'All',
          value: `${formatInteger(data?.starter_payments_made_all, EMPTY_NUMBER)}`
        }
      ]
    },
    channelsOpen: {
      description: 'Channels Open',
      tabs: [
        {
          key: 'day',
          label: '24hr',
          value: `${formatInteger('', EMPTY_NUMBER)}`
        },
        {
          key: 'month',
          label: '30d',
          value: `${formatInteger('', EMPTY_NUMBER)}`
        },
        {
          key: 'all',
          label: 'All',
          value: `${formatInteger('', EMPTY_NUMBER)}`
        }
      ]
    }
  }
}

export const formatMetricsData = ({
  userData,
  exchange,
  flash,
  starter
}: {
  userData?: IUserMetrics
  exchange?: ExchangeDataInterface
  flash?: FlashDataInterface
  starter?: StarterDataInterface
}) => {
  return {
    userData: formatUserData(userData),
    exchange: formatExchangeData(exchange),
    flash: formatFlashData(flash),
    starter: formatStarterData(starter)
  }
}
