import { cn } from '@nextui-org/react'
import { HelpCircle, LucideVerified, UsersIcon } from 'lucide-react'
import { useLocation, useNavigate } from 'react-router-dom'

import { ReactComponent as LockIcon } from '../../assets/icons/icon_lock.svg'
import { ReactComponent as EarnIcon } from '../../assets/icons/sidebar/icon_earn.svg'
import { ReactComponent as LearnIcon } from '../../assets/icons/sidebar/icon_learn.svg'
import { ReactComponent as MetricsIcon } from '../../assets/icons/sidebar/icon_metrics.svg'
import { ReactComponent as NotificationIcon } from '../../assets/icons/sidebar/icon_notification.svg'
import { ReactComponent as UserIcon } from '../../assets/icons/sidebar/icon_user.svg'
import { useAuth } from '../../hooks/use-auth'
import ProtectedComponent from '../ProtectedComponent'

type MenuItem = {
  label: string
  icon: JSX.Element
  url: string
  permissionSlug?: string // optional permission slug
}

const MENU_ITEMS: MenuItem[] = [
  {
    label: 'Users',
    icon: <UserIcon className='size-6' />,
    url: '/users'
  },
  {
    label: 'Metrics',
    icon: <MetricsIcon className='size-6' />,
    url: '/metrics',
    permissionSlug: 'metrics_view'
  },
  {
    label: 'Earn',
    icon: <EarnIcon className='size-6' />,
    url: '/earn'
  },
  {
    label: 'Learn',
    icon: <LearnIcon className='size-6' />,
    url: '/learn'
  },
  {
    label: 'Notifications',
    icon: <NotificationIcon className='size-6' />,
    url: '/notifications'
  },
  {
    label: 'Account Groups',
    icon: <UsersIcon className='size-6' />,
    url: '/account-groups',
    permissionSlug: 'account_groups_view'
  },
  {
    label: 'KYC verifications',
    icon: <LucideVerified className='size-6' />,
    url: '/kyc-verifications',
    permissionSlug: 'kyc_verifications_view'
  },
  {
    label: 'EDD Requests',
    icon: <HelpCircle className='size-6' />,
    url: '/edd-requests',
    permissionSlug: 'edd_requests_view'
  }
]

export function Sidebar() {
  const { isAuthenticated } = useAuth()
  const location = useLocation()
  const navigate = useNavigate()

  if (!isAuthenticated) return null

  return (
    <aside
      style={{
        height: 'calc(100vh - 48px)'
      }}
      className='bg-grey900 sticky top-12 flex w-full max-w-20 flex-col items-start gap-[40px] overflow-hidden border-r border-white/15 pt-[40px] transition-all md:max-w-[280px]'
    >
      {MENU_ITEMS.map(
        ({ icon, permissionSlug, ...menuItem }: MenuItem, i: number) => {
          const active =
            menuItem.url === '/'
              ? location.pathname === menuItem.url
              : location.pathname.startsWith(menuItem.url)

          const menuButton = (
            <button
              key={i}
              className={cn(
                'flex w-full items-center justify-start gap-7 px-7 text-lg font-semibold md:gap-4',
                {
                  'text-foreground': active,
                  'text-foreground/50': !active
                }
              )}
              onClick={() => {
                navigate(menuItem.url)
              }}
            >
              <div className='flex size-6 flex-none items-center justify-center'>
                {icon}
              </div>
              <span>{menuItem.label}</span>
            </button>
          )

          return permissionSlug ? (
            <ProtectedComponent key={i} permissionSlug={permissionSlug}>
              {menuButton}
            </ProtectedComponent>
          ) : (
            menuButton
          )
        }
      )}

      <ProtectedComponent permissionSlug='organization_view'>
        <button
          className={cn(
            'mb-5 mt-auto flex w-full items-center justify-start gap-7 px-7 text-lg font-semibold md:gap-4',
            {
              'text-foreground': location.pathname.startsWith('/organization'),
              'text-foreground/50':
                !location.pathname.startsWith('/organization')
            }
          )}
          onClick={() => {
            navigate('/organization')
          }}
        >
          <div className='flex size-6 flex-none items-center justify-center'>
            <LockIcon />
          </div>
          <span
            className={cn('select-none text-lg font-semibold', {
              'text-foreground': location.pathname.startsWith('/organization'),
              'text-foreground/50':
                !location.pathname.startsWith('/organization')
            })}
          >
            Organization
          </span>
        </button>
      </ProtectedComponent>
    </aside>
  )
}
