import { useQuery } from '@tanstack/react-query'
import clsx from 'clsx'
import { useState } from 'react'

import api from '../../api'
import { Autocomplete } from '../../components/AutocompleteOld'
import { AddButton } from '../../components/Buttons/AddButton'
import { EditButton } from '../../components/Buttons/EditButton'
import { ResultWithPagination } from '../../components/DataTable/DataTable.types'
import { Option } from '../../components/Select/Option'
import { IUser } from '../../types/account/user.interface'

interface Props {
  selectedUser: IUser | null
  onSelect: (option: IUser | null) => void
}

const UsersAutocomplete: React.FC<Props> = ({ selectedUser, onSelect }) => {
  const [search, setSearch] = useState<string>('')
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const { data, isLoading } = useQuery({
    queryKey: ['users-list', search],
    queryFn: async () => {
      const resp = await api.get<ResultWithPagination<IUser>>('/admin/users', {
        params: { search, limit: 50, offset: 0 }
      })

      return resp.data
    }
  })

  return (
    <Autocomplete
      loading={isLoading}
      onInput={e => setSearch(e.target.value || '')}
      selectOnClose
      options={
        data
          ? data?.result.map(user => ({
              key: user.id,
              value: user
            }))
          : []
      }
      value={
        selectedUser
          ? {
              key: selectedUser.id,
              value: selectedUser
            }
          : undefined
      }
      onSelect={option => onSelect(option.value)}
      renderOption={option => (
        <Option
          hover
          option={option}
          startContent={
            <div
              className={clsx(
                'mr-[10px] h-[20px] w-[20px] rounded-full bg-cover bg-center',
                {
                  'bg-white/20': !option.value.avatar
                }
              )}
              style={
                option.value.avatar
                  ? {
                      backgroundImage: `url(${option.value.avatar})`
                    }
                  : undefined
              }
            />
          }
        />
      )}
      getLabel={option => option.value.username}
      endContent={
        !selectedUser ? (
          <AddButton
            className='mr-[20px]'
            title='Add'
            onClick={() => setIsOpen(true)}
          />
        ) : (
          <EditButton
            className='mr-[20px]'
            title='Edit'
            onClick={() => setIsOpen(true)}
          />
        )
      }
      placeholder='Select Receiver'
      open={isOpen}
      onClose={() => setIsOpen(false)}
    />
  )
}

export { UsersAutocomplete }
