import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'

import api from '../../../api'
import { AddButton } from '../../../components/Buttons/AddButton'
import { FormButton } from '../../../components/FormButton'
import { Loader } from '../../../components/Loader'
import { ErrorMessage } from '../../../components/Message/ErrorMessage'
import { Modal } from '../../../components/Modal'
import { IAddAccountToGroup } from '../../../types/account-groups/account-group.interface'
import { UsersAutocomplete } from '../../Notifications/UsersAutocomplete'

export const AddToGroup = ({ groupId }: { groupId: string }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const queryClient = useQueryClient()

  const { mutate, isPending } = useMutation({
    mutationKey: ['addAccountsToAccountGroup'],
    mutationFn: async (data: IAddAccountToGroup) => {
      const resp = await api.post(`/admin/account-groups/${groupId}/add`, {
        accountIds: [data.user?.id]
      })

      return resp.data
    },
    onSuccess: async () => {
      reset()
      queryClient.invalidateQueries()
      queryClient.refetchQueries()
      setIsOpen(false)
      toast.success('User successfully added to group', {
        id: 'account-groups'
      })
    },
    onError: error => {
      toast.error(error.message, {
        id: 'account-groups'
      })
    }
  })

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm<IAddAccountToGroup>({
    defaultValues: {
      user: null
    }
  })

  const onSubmit = (data: IAddAccountToGroup) => {
    mutate(data)
  }

  return (
    <>
      <AddButton onClick={() => setIsOpen(true)} title='Add to Group' />
      <Modal visible={isOpen} onClose={() => setIsOpen(false)} overflow={false}>
        <Loader loading={isPending} type='absolute' />
        <Modal.Header title='Add to group' closable className='mb-[30px]' />
        <Modal.Content>
          <form className='flex w-full flex-col gap-5'>
            <div>
              <Controller
                name='user'
                control={control}
                rules={{ required: 'User is required' }}
                render={({ field }) => {
                  return (
                    <UsersAutocomplete
                      selectedUser={field.value}
                      onSelect={option => {
                        field.onChange(option)
                      }}
                    />
                  )
                }}
              />
              {errors.user?.message && (
                <ErrorMessage message={errors.user.message} />
              )}
            </div>
            <FormButton
              type='submit'
              title={isPending ? 'Adding...' : 'Add'}
              stopPropagation
              preventDefault
              disabled={isPending}
              className='bg-orange550 text-white'
              onClick={handleSubmit(onSubmit)}
            />
          </form>
        </Modal.Content>
      </Modal>
    </>
  )
}
