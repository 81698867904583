import clsx from 'clsx'
import React, { ReactNode, useEffect, useRef, useState } from 'react'

import { Loader } from '../Loader'

import { Option } from './Option'
import styles from './select.module.css'

export interface SelectOption<T> {
  key: string
  value: T
}

export interface SelectProps<T> {
  options: SelectOption<T>[]
  optionsContainerStartContent?: ReactNode
  value?: SelectOption<T>
  placeholder?: string
  onSelect: (option: SelectOption<T>) => void
  endContent?: ReactNode
  renderOption?: (option: SelectOption<T>) => JSX.Element
  getLabel: (option: SelectOption<T>) => unknown
  open?: boolean
  loading?: boolean
  onClose?: () => void
  selectOnClose?: boolean
  className?: string
}

const ANIMATION_DURATION = 200
export const Select = <T,>({
  open,
  loading,
  onClose,
  options,
  optionsContainerStartContent,
  value,
  getLabel,
  onSelect,
  endContent,
  placeholder,
  renderOption,
  selectOnClose = true,
  className
}: SelectProps<T>) => {
  const ref = useRef<HTMLDivElement>(null)
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(!!open)
  const [hide, setHide] = useState(!dropdownOpen)
  const [selectedOption, setSelectedOption] = useState<
    SelectOption<T> | undefined
  >(value || options[0])

  useEffect(() => {
    setDropdownOpen(!!open)
    setHide(!open)
  }, [open])

  useEffect(() => {
    setSelectedOption(value)
  }, [value])

  useEffect(() => {
    const parentElement = ref.current
    if (!parentElement) {
      return
    }
    if (!dropdownOpen) {
      return
    }

    const checkParent = (parent: HTMLElement, child: HTMLElement) => {
      let node = child.parentNode

      while (node != null) {
        if (node == parent) {
          return true
        }
        node = node.parentNode
      }
      return false
    }
    const handler = (e: MouseEvent) => {
      if (checkParent(parentElement, e.target as HTMLElement)) {
        return
      }

      hideOptions()
    }
    document.body.addEventListener('click', handler)
    return () => {
      document.body.removeEventListener('click', handler)
    }
  }, [dropdownOpen])

  const showOptions = () => {
    setDropdownOpen(true)
    setHide(false)
  }

  const hideOptions = () => {
    setHide(true)
    setTimeout(() => {
      setDropdownOpen(false)
      onClose && onClose()
      setHide(false)
    }, ANIMATION_DURATION)
  }

  const onSelectOption = (option: SelectOption<T>) => {
    return () => {
      setSelectedOption(option)
      onSelect(option)
      if (selectOnClose) {
        hideOptions()
      }
    }
  }

  return (
    <div
      ref={ref}
      className={clsx(
        'relative flex w-full items-center justify-between rounded-[10px] border-2 border-gray500/20 bg-gray800 leading-[19px]',
        className
      )}
      onClick={open !== undefined ? undefined : showOptions}
    >
      {selectedOption ? (
        <Option
          option={selectedOption}
          getLabel={loading ? () => 'Loading...' : getLabel}
        />
      ) : (
        <Option
          option={{
            key: 'placeholder',
            value: loading ? 'Loading...' : placeholder || 'Select'
          }}
          labelClassName='text-white/50'
        />
      )}
      {endContent}
      {dropdownOpen && (
        <div
          className={clsx(
            'absolute left-0 top-0 z-2 flex max-h-[280px] w-full flex-col overflow-y-auto',
            'rounded-[10px] border-2 border-gray500/20 bg-gray800',
            {
              [styles.selectDropdownVisible]: !hide,
              [styles.selectDropdownHidden]: hide
            }
          )}
          style={{
            animationDuration: `${ANIMATION_DURATION}ms`
          }}
        >
          <Loader loading={!!loading} type='absolute' />
          {optionsContainerStartContent}
          {options.map(option => (
            <React.Fragment key={option.key}>
              {renderOption ? (
                React.cloneElement(renderOption(option), {
                  onClick: onSelectOption(option),
                  getLabel,
                  selected: option.key === selectedOption?.key
                })
              ) : (
                <Option
                  selected={option.key === selectedOption?.key}
                  hover
                  option={option}
                  getLabel={getLabel}
                  onClick={onSelectOption(option)}
                />
              )}
            </React.Fragment>
          ))}
        </div>
      )}
    </div>
  )
}
