import { useMutation, useQueryClient } from '@tanstack/react-query'
import toast from 'react-hot-toast'

import api from '../../../api'
import { errorCatch } from '../../../api/error'
import { DeleteButton } from '../../../components/Buttons/DeleteButton'

interface Props {
  id: string
}

const DeleteCreator: React.FC<Props> = ({ id }) => {
  const queryClient = useQueryClient()

  const { mutate } = useMutation({
    mutationKey: ['deleteVideo'],
    mutationFn: async (id: string) => {
      await api.delete(`admin/learn/creators/${id}`)
    },
    onSuccess: () => {
      queryClient.invalidateQueries()
      toast.success('Creator successfully deleted!', {
        id: 'delete-creator'
      })
    },
    onError: error => {
      const errorMessage = errorCatch(error)
      toast.error(
        errorMessage || 'An error occurred while deleting the creator.'
      )
    }
  })

  return <DeleteButton title='Delete' onDelete={() => mutate(id)} />
}

export default DeleteCreator
