import { Helmet } from 'react-helmet'

import { DataTable } from '../../../components/DataTable'
import ProtectedComponent from '../../../components/ProtectedComponent'
import Tabs from '../Tabs'

import CreateOrUpdateCategory from './CreateOrUpdateCategory'
import { columns } from './columns'

const Categories: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>STBA Admin - Learn Categories</title>
      </Helmet>
      <section className='flex w-full flex-col gap-2 p-2 md:gap-4 md:px-4 md:py-6 lg:px-8 lg:py-10'>
        <h1 className='border-b border-white border-opacity-10 pb-7 text-2xl font-bold text-white md:text-3xl'>
          Learn
        </h1>
        <div className='flex w-full justify-between'>
          <Tabs />
          <ProtectedComponent permissionSlug='learn_create'>
            <CreateOrUpdateCategory url='admin/learn/categories' />
          </ProtectedComponent>
        </div>
        <DataTable
          url='admin/learn/categories'
          columns={columns}
          totalText='categories'
          defaultSortBy='name'
          defaultSortOrder='asc'
        />
      </section>
    </>
  )
}

export default Categories
