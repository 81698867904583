import clsx from 'clsx'
import { useState } from 'react'

import { Button } from '../Button'
import { FormButton } from '../FormButton'
import { Popup } from '../Popup'

interface DeleteButtonProps {
  title: string
  question?: string
  onDelete: () => void
  className?: string
  disabled?: boolean
}

export const DeleteButton = ({
  title,
  question,
  onDelete,
  className,
  disabled
}: DeleteButtonProps) => {
  const [open, setOpen] = useState<boolean>(false)

  const onOpen = () => {
    if (disabled) {
      return
    }
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  return (
    <Popup
      open={open}
      onOpen={onOpen}
      onClose={onClose}
      content={
        <div className='mt-[30px] flex gap-[10px]'>
          <FormButton
            type='button'
            title='Yes'
            stopPropagation={false}
            className='bg-orange500/10 text-orange500'
            onClick={() => {
              onClose()
              onDelete()
            }}
          />
          <FormButton
            onClick={onClose}
            type='button'
            title='No'
            stopPropagation={false}
            className='bg-white/10 text-white'
          />
        </div>
      }
      title={question || 'Are you sure you want to delete?'}
      trigger='click'
      verticalPosition='top'
      horizontalPosition='end'
      className='mt-[4px]'
    >
      <Button
        disabled={disabled}
        title={title}
        className={clsx(
          'justify-center bg-orange500/20 px-[20px] text-center text-[12px] font-bold text-orange500 transition-all',
          className
        )}
      />
    </Popup>
  )
}
