import { useMutation } from '@tanstack/react-query'
import { useState } from 'react'
import { useForm } from 'react-hook-form'

import { ReactComponent as CloseIcon } from '../../assets/icons/icon_close.svg'
import { Drawer } from '../../components/Drawer'
import { FormButton } from '../../components/FormButton'
import { Loader } from '../../components/Loader'
import ProtectedComponent from '../../components/ProtectedComponent'
import { IBankEddRequest } from '../../types/bank-edd-request/bank-request.interface'
import { EditButton } from '../Users/Buttons/EditButton'

import AccountSelect from './AccountSelect'

interface Props {
  initial: IBankEddRequest
}

const whitelistedFields: string[] = [
  'first_name',
  'last_name',
  'address_one',
  'address_two',
  'city',
  'zip',
  'id_number_type',
  'phone_number',
  'employment_status',
  'industry',
  'source_of_funds',
  'onboarded_location',
  'jurisdiction_code',
  'citizenship_code'
]

const fieldLabels: Record<string, string> = {
  first_name: 'First Name',
  last_name: 'Last Name',
  address_one: 'Address One',
  address_two: 'Address Two',
  city: 'City',
  zip: 'Zip',
  id_number_type: 'ID Number Type',
  phone_number: 'Phone Number',
  employment_status: 'Employment Status',
  industry: 'Industry',
  source_of_funds: 'Source Of Funds',
  onboarded_location: 'Onboarded Location',
  jurisdiction_code: 'Jurisdiction Code',
  citizenship_code: 'Citizenship Code'
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const renderField = (key: string, value: any, visibleKey: string) => {
  if (!value[visibleKey]) {
    return null
  }
  return (
    <div
      className='flex flex-row justify-between gap-2 text-base font-medium text-foreground/50'
      key={key}
    >
      {fieldLabels[key] || key}: <p>{value[key] || 'N/A'}</p>
    </div>
  )
}

const ViewEDDRequest: React.FC<Props> = ({ initial }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const { handleSubmit } = useForm()

  const { isPending, mutate } = useMutation({
    mutationKey: ['pass-edd-request']
    // mutationFn: (data: IBankEddRequest) => {
    //   // console.log(data)
    //   return Promise.resolve()
    // }
  })

  const onSubmit = async () => mutate() // initial

  return (
    <>
      <EditButton onClick={() => setIsOpen(true)} title='View' />
      <Drawer
        contentClassName='bg-gray800 border-solid border-gray500/50 w-full sm:w-[480px]'
        open={isOpen}
        position='right'
        onClose={() => setIsOpen(false)}
      >
        <Loader type='absolute' loading={isPending} />
        <div className='flex h-full flex-col gap-5 overflow-y-auto p-5 px-14 sm:py-10'>
          <div className='flex flex-row items-center justify-between'>
            <h2 className='text-2xl font-semibold'>EDD Request</h2>
            <CloseIcon
              onClick={() => setIsOpen(false)}
              className='cursor-pointer fill-white opacity-50 transition-opacity hover:opacity-80 active:opacity-100'
            />
          </div>
          <form className='flex flex-col gap-5'>
            <label className='flex flex-col gap-2'>
              <p className='font-semibold'>Account</p>
              <AccountSelect
                initialAccount={initial?.account}
                accountId={initial.account.id}
                setAccountId={() => {}}
              />
            </label>

            <p className='font-semibold'>Details</p>
            <div className='flex flex-col gap-4'>
              {Object.entries(initial)
                .filter(([key]) => whitelistedFields.includes(key))
                .map(([key]) => renderField(key, initial, `${key}_visible`))}
            </div>

            <ProtectedComponent permissionSlug='edd_requests_approve'>
              <FormButton
                onClick={handleSubmit(onSubmit)}
                preventDefault
                stopPropagation
                title='Confirm'
                className='mt-2 bg-orange550 text-white'
              />
            </ProtectedComponent>
          </form>
        </div>
      </Drawer>
    </>
  )
}

export default ViewEDDRequest
