import { ColumnDef } from '@tanstack/react-table'

import { ReactComponent as ActiveIcon } from '../../../assets/icons/icon_accepted.svg'
import { ReactComponent as UnactiveIcon } from '../../../assets/icons/icon_rejected.svg'
import { DataTableColumnHeader } from '../../../components/DataTable/ColumnHeader'
import ProtectedComponent from '../../../components/ProtectedComponent'
import { useAuth } from '../../../hooks/use-auth'
import { IAdmin } from '../../../types/account/admin.interface'

import CreateOrUpdateMember from './CreateOrEditMember'
import DeleteMember from './DeleteMember'

export const columns: ColumnDef<IAdmin>[] = [
  {
    accessorKey: 'name',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Name' />
    },
    enableSorting: false
  },
  {
    accessorKey: 'email',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Email' />
    },
    cell: ({ row }) => {
      return <p className='opacity-50'>{row.original.email}</p>
    },
    enableSorting: false
  },
  {
    accessorKey: 'roles',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Role' />
    },
    cell: ({ row }) => {
      return (
        <div className='flex flex-row gap-1'>{row.original.role?.name}</div>
      )
    },
    enableSorting: false
  },
  {
    accessorKey: 'host',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Host' />
    },
    cell: ({ row }) => {
      return <div className='opacity-50'>{row.original.host || 'N/A'}</div>
    }
  },
  {
    accessorKey: 'port',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Port' />
    },
    cell: ({ row }) => {
      return <div className='opacity-50'>{row.original.port || 'N/A'}</div>
    }
  },
  {
    accessorKey: 'is_subscribed_bank_webhooks',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Redirect' />
    },
    cell: ({ row }) => {
      return row.original.is_subscribed_bank_webhooks ? (
        <ActiveIcon />
      ) : (
        <UnactiveIcon />
      )
    },
    enableSorting: false
  },
  {
    accessorKey: 'actions',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='' />
    },
    cell: ({ row }) => {
      const { admin } = useAuth()
      if (admin?.id === row.original.id) return null

      return (
        <div className='flex w-fit flex-row gap-1 border-l-2 border-border pl-4'>
          <ProtectedComponent permissionSlug='admins_update'>
            <CreateOrUpdateMember
              initial={row.original}
              url={`/admin/admins/${row.original.id}`}
            />
          </ProtectedComponent>
          {row.original.role?.is_super_role === false && (
            <ProtectedComponent permissionSlug='admins_delete'>
              <DeleteMember id={row.original.id} />
            </ProtectedComponent>
          )}
        </div>
      )
    },
    enableSorting: false
  }
]
