import { useQuery } from '@tanstack/react-query'
import clsx from 'clsx'
import { useMemo } from 'react'
import { Helmet } from 'react-helmet'

import api from '../../api'
import { DataTable } from '../../components/DataTable'
import { SubDescriptionSectionItem } from '../../components/InfoSection/SectionItem/SubDescriptionSectionItem'
import { EarnDataInterface } from '../../types/metrics/metrics.interface'

import { columns } from './columns'
import styles from './earn.module.css'
import { formatEarnData } from './earnFormater'

const Earn = () => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ['metricsEarn'],
    queryFn: async () => {
      const resp = await api.get<EarnDataInterface>('admin/metrics/earn')

      return resp.data
    },
    retry: 1,
    enabled: true
  })

  const formattedEarnData = useMemo(() => {
    return formatEarnData(data)
  }, [data])

  return (
    <>
      <Helmet>
        <title>STBA Admin - Earn</title>
      </Helmet>
      <section className='flex w-full flex-col gap-2 p-2 md:gap-4 md:px-4 md:py-6 lg:px-8 lg:py-10'>
        <h1 className='border-b border-white border-opacity-10 pb-7 text-2xl font-bold text-white md:text-3xl'>
          Earn
        </h1>
        <div className='mt-2 flex flex-row flex-wrap gap-[30px] md:mt-4'>
          <SubDescriptionSectionItem
            labelClassName='!text-purple400'
            label={formattedEarnData.earnWalletBalance.label}
            description={formattedEarnData.earnWalletBalance.description}
            subDescription={formattedEarnData.earnWalletBalance.subDescription}
            className={clsx(
              'w-full min-w-0 flex-none',
              styles.sectionItemColumnFour
            )}
            loading={isLoading}
            error={isError ? 'Could not get Earn metrics' : undefined}
          />
          <SubDescriptionSectionItem
            label={formattedEarnData.totalFuturePayout.label}
            description={formattedEarnData.totalFuturePayout.description}
            subDescription={formattedEarnData.totalFuturePayout.subDescription}
            className={clsx(
              'w-full min-w-0 flex-none',
              styles.sectionItemColumnFour
            )}
            loading={isLoading}
            error={isError ? 'Could not get Earn metrics' : undefined}
          />
          <SubDescriptionSectionItem
            labelClassName='!text-orange550'
            label={formattedEarnData.totalDailyEarnRate.label}
            description={formattedEarnData.totalDailyEarnRate.description}
            subDescription={formattedEarnData.totalDailyEarnRate.subDescription}
            className={clsx(
              'w-full min-w-0 flex-none',
              styles.sectionItemColumnFour
            )}
            loading={isLoading}
            error={isError ? 'Could not get Earn metrics' : undefined}
          />
          <SubDescriptionSectionItem
            labelClassName='!text-orange500'
            label={formattedEarnData.totalPaidOut.label}
            description={formattedEarnData.totalPaidOut.description}
            subDescription={formattedEarnData.totalPaidOut.subDescription}
            className={clsx(
              'w-full min-w-0 flex-none',
              styles.sectionItemColumnFour
            )}
            loading={isLoading}
            error={
              isError && 'Could not get Earn metrics'
                ? 'Could not get Earn metrics'
                : undefined
            }
          />
        </div>
        <DataTable
          columns={columns}
          url='/admin/earn/rankings'
          totalText='users'
          className='mt-2 md:mt-4'
        />
      </section>
    </>
  )
}

export default Earn
