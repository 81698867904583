import { Helmet } from 'react-helmet'

import { DataTable } from '../../components/DataTable'
import ProtectedComponent from '../../components/ProtectedComponent'

import { CreateGroup } from './CreateGroup'
import { columns } from './columns'

const AccountGroups = () => {
  return (
    <ProtectedComponent permissionSlug='account_groups_view' isPage>
      <Helmet>
        <title>STBA Admin - Account Groups</title>
      </Helmet>
      <section className='flex w-full flex-col gap-2 p-2 md:gap-4 md:px-4 md:py-6 lg:px-8 lg:py-10'>
        <section className='flex w-full flex-wrap justify-between border-b border-white border-opacity-10 pb-7'>
          <h1 className='text-2xl font-bold text-white md:text-3xl'>
            Account Groups
          </h1>
          <ProtectedComponent permissionSlug='account_groups_create'>
            <CreateGroup />
          </ProtectedComponent>
        </section>
        <DataTable
          url='/admin/account-groups'
          columns={columns}
          totalText='account-groups'
        />
      </section>
    </ProtectedComponent>
  )
}

export default AccountGroups
