import { useMutation, useQueryClient } from '@tanstack/react-query'
import toast from 'react-hot-toast'

import api from '../../../api'
import { DeleteButton } from '../../../components/Buttons/DeleteButton'

interface Props {
  groupId: string
  membershipId: string
}

const RemoveFromGroup: React.FC<Props> = ({ groupId, membershipId }) => {
  const queryClient = useQueryClient()

  const { mutate } = useMutation({
    mutationKey: ['removeAccountsFromAccountGroup'],
    mutationFn: async (id: string) => {
      await api.put(`/admin/account-groups/${groupId}/remove`, {
        accountGroupMembershipIds: [id]
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries()
      queryClient.refetchQueries()
      toast.success('User successfully removed from the group', {
        id: 'account-groups'
      })
    },
    onError: error => {
      toast.error(error.message, {
        id: 'account-groups'
      })
    }
  })

  return (
    <>
      <DeleteButton onDelete={() => mutate(membershipId)} title='Remove' />
    </>
  )
}

export default RemoveFromGroup
