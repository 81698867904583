import clsx from 'clsx'

import * as icons from './icons'

export function Icon({
  icon,
  className
}: {
  icon: icons.Icon
  className?: string
}) {
  const IconComponent = icons[icon]

  if (!IconComponent) {
    return null
  }

  return <IconComponent className={clsx(className, 'h-4 w-4 text-white')} />
}
