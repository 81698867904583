import { useQuery } from '@tanstack/react-query'

import api from '../../api'
import { formatInteger, formatNumber } from '../../utils/utils'
import { Icon } from '../Icon'

const AvgFeeAndBtcPrice: React.FC = () => {
  const {
    data: btcPrice,
    isError: isBtcError,
    isLoading: isBtcLoading
  } = useQuery({
    queryKey: ['btcPrice'],
    queryFn: async () => {
      const resp = await api.get<string>('/admin/btc-price')

      return resp.data
    }
  })
  const {
    data: avgFee,
    isError: isAvgFeeError,
    isLoading: isAvgFeeLoading
  } = useQuery({
    queryKey: ['avgFee'],
    queryFn: async () => {
      const resp = await api.get<string>('/admin/avg-fee')

      return resp.data
    }
  })

  if (isBtcLoading || isAvgFeeLoading) return null

  return (
    <section className='ml-auto mr-10 hidden flex-row gap-5 md:flex'>
      {!isBtcError && (
        <div className='flex flex-row items-center gap-1'>
          <Icon className='h-6 w-6 fill-white' icon='bitcoin' />
          <span className='text-sm font-semibold text-foreground'>
            ${formatInteger(btcPrice, '00,000')}
          </span>
        </div>
      )}
      {!isAvgFeeError && (
        <div className='flex flex-row items-center gap-1'>
          <span className='text-sm font-semibold text-foreground'>
            ${formatNumber(avgFee, '0.00')}
          </span>
          <span className='text-sm font-semibold text-foreground/50'>
            Avg Fee
          </span>
        </div>
      )}
    </section>
  )
}

export default AvgFeeAndBtcPrice
