import React from 'react'

import { Input } from './Input'
import { Select, SelectProps } from './Select'

interface AutocompleteProps<T>
  extends Omit<SelectProps<T>, 'optionsContainerStartContent'> {
  onInput?: (e: React.ChangeEvent<HTMLInputElement>) => void
  loading?: boolean
  emptyOptionsDescription?: string
}

export const Autocomplete = <T,>({
  onInput,
  emptyOptionsDescription = 'No options found.',
  ...props
}: AutocompleteProps<T>) => {
  return (
    <Select
      {...props}
      optionsContainerStartContent={
        <div className='sticky top-0 bg-gray800'>
          <Input
            type='text'
            className='border-none'
            wrapperClassName='flex-none'
            placeholder={'Search'}
            onChange={onInput}
            name='autocomplete'
            autoFocus
          />
          <div className='my-[2px] h-[2px] w-full flex-none bg-white/10' />
          {!props.options.length && (
            <p className='p-[20px] text-center text-[16px] font-bold text-white'>
              {emptyOptionsDescription}
            </p>
          )}
        </div>
      }
    />
  )
}
