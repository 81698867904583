import clsx from 'clsx'

interface FormButtonProps {
  type?: 'submit' | 'reset' | 'button'
  title: string
  className?: string
  stopPropagation?: boolean
  preventDefault?: boolean
  onClick?: () => void
  disabled?: boolean
}

export const FormButton = ({
  type,
  title,
  className,
  stopPropagation,
  preventDefault,
  onClick,
  disabled
}: FormButtonProps) => {
  return (
    <button
      type={type}
      disabled={disabled}
      className={clsx(
        'leading-22 h-[60px] w-full rounded-[200px] p-[14px]',
        'text-[18px] font-bold outline-[22px]',
        {
          'hover:opacity-70 active:opacity-50': !disabled,
          'opacity-20': disabled
        },
        className
      )}
      onClick={e => {
        stopPropagation && e.stopPropagation()
        preventDefault && e.preventDefault()
        onClick && onClick()
      }}
    >
      {title}
    </button>
  )
}
