import { DataTable } from '../../../components/DataTable'

import { columns } from './columns'

export const TeamMembers = () => {
  return (
    <DataTable
      url='admin/admins'
      totalText='members'
      columns={columns}
      tableClassName='md:!max-h-[calc(100vh-550px)]'
    />
  )
}
