import { useMutation } from '@tanstack/react-query'
import toast from 'react-hot-toast'

import api from '../../../api'
import { errorCatch } from '../../../api/error'
import { DeleteButton } from '../../../components/Buttons/DeleteButton'
import { IAdmin } from '../../../types/account/admin.interface'

interface Props {
  admin: IAdmin
  onSuccess: () => void
}

const ResetPassword: React.FC<Props> = ({ admin, onSuccess }) => {
  const { mutate, isPending } = useMutation({
    mutationKey: ['resetPassword', admin.id],
    mutationFn: async (id: string) => {
      return await api.put(`/admin/admins/${id}/reset-password`)
    },
    onSuccess: () => {
      toast.success(
        `Password reset. Email with new password sent to administrator.`,
        {
          id: 'reset-password',
          duration: 5000
        }
      )
      onSuccess()
    },
    onError: error => {
      const errorMessage = errorCatch(error)
      toast.error(
        errorMessage || 'An error occurred while resetting the password.',
        {
          id: 'reset-password'
        }
      )
    }
  })
  return (
    <DeleteButton
      title='Reset Password'
      onDelete={() => mutate(admin.id)}
      disabled={isPending}
      className={
        'mt-2 h-14 justify-center bg-red500/10 text-center text-lg font-bold text-red500 transition-all'
      }
      question='Are you sure you want to reset the password?'
    />
  )
}

export default ResetPassword
