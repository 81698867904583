import clsx from 'clsx'

import { SectionItem, SectionItemProps } from './index'

interface SubDescriptionSectionItemProps extends SectionItemProps {
  subDescription?: string
  description?: string
}

export const SubDescriptionSectionItem = ({
  loading,
  className,
  labelClassName,
  label,
  description,
  subDescription,
  style,
  error
}: SubDescriptionSectionItemProps) => {
  return (
    <SectionItem
      loading={loading}
      className={className}
      labelClassName={labelClassName}
      label={label}
      style={style}
      error={error}
      description={
        <div className='flex w-full flex-col items-center gap-[20px]'>
          <p
            title={description}
            className={clsx(
              'long-text px-[32px] text-center text-[20px] font-medium leading-[30px] text-white/50',
              {
                blur: loading || error
              }
            )}
          >
            {description}
          </p>
          <p
            title={subDescription}
            className='long-text px-[32px] text-center text-[20px] font-semibold leading-[30px] text-white'
          >
            {subDescription}
          </p>
        </div>
      }
    />
  )
}
